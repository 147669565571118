import { cloneDeep, sortedUniqBy } from 'lodash';

import { DataFrameDef } from '../model/definitions/DataFrameDef';

function findMostFrequent(array: number[]): number {
  if (array.length === 0) {
    return 0; // Return null for empty array
  }

  const frequencyMap: { [key: number]: number } = {};
  let maxFrequency = 0;
  let mostFrequentElement = array[0]; // Initialize with the first element

  array.forEach(function (element) {
    // Count the frequency of each element
    frequencyMap[element] = (frequencyMap[element] || 0) + 1;

    // Update maxFrequency and mostFrequentElement if necessary
    if (frequencyMap[element] > maxFrequency) {
      maxFrequency = frequencyMap[element];
      mostFrequentElement = element;
    }
  });

  return mostFrequentElement;
}
const getMedianDifferenceFromTimestamp = (frames: DataFrameDef[]) => {
  const timestamps: number[] = frames.map((item) => item.timestamp * 1000); // Convert to milliseconds

  // Calculate differences between consecutive timestamps in seconds
  const differencesInSeconds: number[] = timestamps
    .slice(1)
    .map((timestamp, index) => (timestamp - timestamps[index]) / 1000);

  // Sort the differences
  const sortedDifferences = differencesInSeconds.sort((a, b) => a - b);

  // Find the median of the sorted differences
  /*   const medianIndex = Math.floor(sortedDifferences.length / 2);
  return sortedDifferences.length % 2 === 0
    ? (sortedDifferences[medianIndex - 1] + sortedDifferences[medianIndex]) / 2
    : sortedDifferences[medianIndex]; */
  return findMostFrequent(sortedDifferences);
};

const getNormalisedFrames = (data: DataFrameDef[]) => {
  const result = cloneDeep(data);
  for (let i = 0; i < result.length; i++) {
    result[i].timestamp = Math.round(result[i].timestamp / 60) * 60;
  }
  const frames = sortedUniqBy(
    result.sort((a, b) => a.timestamp - b.timestamp),
    'timestamp',
  );
  const updatedFrames = [];
  const medianDifference = Math.round(getMedianDifferenceFromTimestamp(frames));
  for (let i = 0; i < frames.length - 1; i++) {
    updatedFrames.push(frames[i]);
    const timestampDifference = frames[i + 1].timestamp - frames[i].timestamp;
    if (timestampDifference >= medianDifference * 1.9) {
      const numberOfGaps = Math.floor(timestampDifference / medianDifference);
      const gapSize = Math.floor(timestampDifference / numberOfGaps);
      for (let j = 1; j <= numberOfGaps; j++) {
        const newTimestamp = frames[i].timestamp + j * gapSize;
        updatedFrames.push({
          frameId: frames[i].frameId,
          timestamp: newTimestamp,
        });
      }
    }
  }

  // In case 0 frames are available updatedFrames should stay empty
  if (frames[frames.length - 1]) {
    updatedFrames.push(frames[frames.length - 1]);
  }
  return updatedFrames;
};
export { getMedianDifferenceFromTimestamp, getNormalisedFrames };
