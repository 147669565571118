import { Map } from 'ol';
import { ProjectionLike } from 'ol/proj';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { WeatherPosterDef } from '../../model/definitions/WeatherPosterDef';
import { ActiveDef, setPoster } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import { AnimationElement } from './AnimationElement';
import ElementContainer from './ElementContainer';
import { ForecastElement } from './ForecastElement';
import { ImageElement } from './ImageElement';
import { ObservedElement } from './ObservedElement';
import { PointDateElement } from './PointDateElement';
import { PointLocationElement } from './PointLocationElement';
import { TextElement } from './TextElement';
import { VideoElement } from './VideoElement';

interface PosterElementProps {
  panelProps: WeatherPosterDef;
  canvas?: { cnvWidth?: number; cnvHeight?: number };
  disabled: boolean;
  isMapOverlay?: boolean;
  parentMapId?: string;
  mapRef?: React.MutableRefObject<Map | null>;
  projectionCalc?: ProjectionLike;
  sceneId: string;
  parentTime?: TimeControlDef[];
}
export const PosterElement = ({
  panelProps,
  canvas,
  disabled,
  isMapOverlay = false,
  parentMapId,
  mapRef,
  projectionCalc,
  sceneId,
  parentTime,
}: PosterElementProps) => {
  const {
    positionControl,
    textPanels,
    imagePanels,
    animationPanels,
    videoPanels,
    observedWDElements,
    pointDates,
    pointLocation,
    forecastWDElements,
    timeControls,
    id,
  } = panelProps;
  const [parentSize, setParentSize] = useState<PositionControlDef>(positionControl);

  const dispatch = useDispatch();
  const { biasMode, activeElement } = useSelector<RootState, ActiveDef>((state) => state.active);

  const elementCanvas = { cnvWidth: positionControl.w, cnvHeight: positionControl.h };

  const renderTextElements = () =>
    textPanels.map((text) => (
      <TextElement
        sceneId={sceneId}
        key={text.id}
        panelProps={text}
        canvas={elementCanvas}
        disabled={disabled}
        parentTime={timeControls}
        inPoster
        posterId={id}
        parentSize={parentSize}
        isMapOverlay={isMapOverlay}
        mapId={parentMapId}
        geoPosterId={panelProps.id}
      />
    ));
  const renderImageElements = () =>
    imagePanels.map((text) => (
      <ImageElement
        scene={sceneId}
        key={text.id}
        panelProps={text}
        canvas={elementCanvas}
        disabled={disabled}
        parentTime={timeControls}
        inPoster
        posterId={id}
        parentSize={parentSize}
        isMapOverlay={isMapOverlay}
        mapId={parentMapId}
        geoPosterId={panelProps.id}
      />
    ));
  const renderAnimationElements = () =>
    animationPanels.map((text) => (
      <AnimationElement
        sceneId={sceneId}
        key={text.id}
        panelProps={text}
        canvas={elementCanvas}
        disabled={disabled}
        parentTime={timeControls}
        inPoster
        posterId={id}
        parentSize={parentSize}
        isMapOverlay={isMapOverlay}
        mapId={parentMapId}
        geoPosterId={panelProps.id}
      />
    ));
  const renderVideoElements = () =>
    videoPanels.map((text) => (
      <VideoElement
        sceneId={sceneId}
        key={text.id}
        panelProps={text}
        canvas={elementCanvas}
        disabled={disabled}
        parentTime={timeControls}
        inPoster
        posterId={id}
        parentSize={parentSize}
        isMapOverlay={isMapOverlay}
        mapId={parentMapId}
        geoPosterId={panelProps.id}
      />
    ));
  const renderObservedElements = () =>
    observedWDElements.map((text) => (
      <ObservedElement
        sceneId={sceneId}
        key={text.id}
        panelProps={text}
        canvas={elementCanvas}
        disabled={disabled}
        parentTime={timeControls}
        inPoster
        posterId={id}
        parentSize={parentSize}
        isMapOverlay={isMapOverlay}
        mapId={parentMapId}
        geoPosterId={panelProps.id}
      />
    ));
  const renderDateElements = () =>
    pointDates?.map((text) => (
      <PointDateElement
        sceneId={sceneId}
        key={text.id}
        panelProps={text}
        canvas={elementCanvas}
        disabled={disabled}
        parentTime={timeControls}
        inPoster
        posterId={id}
        parentSize={parentSize}
        isMapOverlay={isMapOverlay}
        mapId={parentMapId}
        geoPosterId={panelProps.id}
        scene={panelProps}
      />
    ));
  const renderLocationElements = () =>
    pointLocation?.map((text) => (
      <PointLocationElement
        sceneId={sceneId}
        key={text.id}
        panelProps={text}
        canvas={elementCanvas}
        disabled={disabled}
        parentTime={timeControls}
        inPoster
        posterId={id}
        parentSize={parentSize}
        isMapOverlay={isMapOverlay}
        mapId={parentMapId}
        geoPosterId={panelProps.id}
        scene={panelProps}
      />
    ));
  const renderForecastElements = () =>
    forecastWDElements.map((text) => (
      <ForecastElement
        sceneId={sceneId}
        key={text.id}
        panelProps={text}
        canvas={elementCanvas}
        disabled={disabled}
        parentTime={timeControls}
        inPoster
        posterId={id}
        parentSize={parentSize}
        isMapOverlay={isMapOverlay}
        mapId={parentMapId}
        geoPosterId={panelProps.id}
      />
    ));
  const posterRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (posterRef.current && posterRef.current?.offsetHeight && posterRef.current?.offsetWidth)
      setParentSize((pSize) => ({
        ...pSize,
        w: posterRef.current!.offsetWidth,
        h: posterRef.current!.offsetHeight,
      }));
  }, [panelProps]);

  return (
    <ElementContainer
      sceneId={sceneId}
      canvas={canvas}
      panelProps={panelProps}
      disabled={disabled || biasMode}
      // @ts-ignore
      type={isMapOverlay ? 'mapOverlay' : 'weatherPosters'}
      visibility={panelProps.enabled}
      isMapOverlay={isMapOverlay}
      parentMapId={parentMapId}
      geoPosterId={panelProps.id}
      isDraggable={activeElement === id}
      parentTime={parentTime}
      parentSize={parentSize}
    >
      <div
        onClick={() => dispatch(setPoster({ posterId: id }))}
        style={{ width: '100%', height: '100%', position: 'relative' }}
        ref={posterRef}
        className="move-element-container"
      >
        {renderTextElements()}
        {renderImageElements()}
        {renderAnimationElements()}
        {renderVideoElements()}
        {renderObservedElements()}
        {renderForecastElements()}
        {renderDateElements()}
        {renderLocationElements()}
      </div>
    </ElementContainer>
  );
};
