import moment from 'moment-timezone';

import { useGetLatestDate } from '../hooks/apiPikAlert/useGetLatestDate';
import { useGetVehicles } from '../hooks/apiPikAlert/useGetVehicles';
import { vehicleModal } from './mapComponents/VehicleModal';

const TableHeader = ({ headers }) => (
  <thead className="sticky -top-[1px] z-10">
    <tr className="bg-gray-100">
      {headers.map((header) => (
        <th
          key={header}
          className="py-2 px-4 border-b border-gray-300 text-left font-semibold text-gray-700"
        >
          {header}
        </th>
      ))}
    </tr>
  </thead>
);

const TableCell = ({ value }) => <td className="py-2 px-4 text-gray-700">{value}</td>;

const TableRow = ({ vehicle, onDoubleClick }) => (
  <tr
    className="border-b border-gray-300 cursor-pointer hover:bg-gray-100"
    onDoubleClick={onDoubleClick}
  >
    <TableCell value={vehicle.id} />
    <TableCell value={formatObservedAt(vehicle.obs_time)} />
    <TableCell value={vehicle.speed_mph} />
    <TableCell value={vehicle.temp_f} />
    <TableCell value={vehicle.road_temp_f} />
    <TableCell value={vehicle.heading_deg} />
    <TableCell value={vehicle.elevation} />
    <TableCell value={vehicle.humidity} />
    <TableCell value={vehicle.pressure} />
    <TableCell value={vehicle.auto_brake} />
    <TableCell value={vehicle.spreader} />
    <TableCell value={vehicle.traction_control} />
    <TableCell value={vehicle.wiper_status} />
    <TableCell value={vehicle.lat} />
    <TableCell value={vehicle.lon} />
  </tr>
);

const TableBody = ({ vehicles, onVehicleClick }) => (
  <tbody>
    {vehicles.map((vehicle, index) => (
      <TableRow
        key={vehicle.id + index}
        vehicle={vehicle}
        onDoubleClick={() => onVehicleClick(vehicle)}
      />
    ))}
  </tbody>
);

const formatObservedAt = (timestamp) => {
  return moment.unix(timestamp).tz('America/Denver').format('H:mm [on] M/D');
};

const VehiclesTable = ({ setTab }) => {
  const { data: latest } = useGetLatestDate();
  const prop = 'latest_vehicles';
  const timestamp = (latest ?? [])?.find((item) => item.dir === prop)?.latest_time;

  const { data } = useGetVehicles(timestamp);
  if (!data?.districts?.length) return null;

  const handleVehicle = (vehicle) => {
    vehicleModal(vehicle);
    setTab('map');
  };

  const tableHeaders = [
    'Vehicle',
    'Observed At',
    'Speed (mph)',
    'Temperature (°F)',
    'Road Temp (°F)',
    'Heading (°)',
    'Elevation',
    'Humidity',
    'Pressure',
    'Auto Brake',
    'Spreader',
    'Traction Control',
    'Wiper Status',
    'Latitude',
    'Longitude',
  ];

  return (
    <div className="mx-auto px-4 pl-[75px]">
      <div className="p-8">
        <h2 className="text-black uppercase break-words text-2xl text-gray-700 font-bold mb-4">
          VEHICLES
        </h2>
        <table className="min-w-full table-fixed border-collapse border border-gray-300">
          <TableHeader headers={tableHeaders} />

          {data.districts.map((district) => (
            <TableBody
              key={district.id}
              vehicles={district.vehicles}
              onVehicleClick={handleVehicle}
            />
          ))}
        </table>
      </div>
    </div>
  );
};

export default VehiclesTable;
