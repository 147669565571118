import { useQuery } from 'react-query';

import { axiosInstance } from './axiosInstance';

const failedFontVariantIds = new Set<string>();

export const useGetAllVariants = (fontVariants: { name?: string; fontVariantId?: string }[]) => {
  const fontVariantsIds = fontVariants.map((variant) => variant.fontVariantId);
  return useQuery(
    [...fontVariantsIds],
    async () => {
      const variantPromises = fontVariantsIds.map(async (variantId: any, index) => {
        if (failedFontVariantIds.has(variantId)) {
          return null;
        }

        try {
          const response = await axiosInstance.get(`font-variant/${variantId}`);
          return { fontVariant: response.data, name: fontVariants[index].name };
        } catch (error: any) {
          if (error.response && (error.response.status === 404 || error.response.status === 500)) {
            failedFontVariantIds.add(variantId);
          }
          return null;
        }
      });

      const variantResponses = await Promise.all(variantPromises);
      return variantResponses.filter(Boolean);
    },
    {
      enabled: !!fontVariants.length,
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: false,
    },
  );
};
