import { PictureOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useState } from 'react';

import basic from '../../../assets/images/basic1.png';
import satellite from '../../../assets/images/satellite1.png';

const MapStyle = ({ switchToOSM, switchToSatellite }) => {
  const [value, setValue] = useState('osm');

  return (
    <div className="absolute text-sm md:text-base bottom-2 left-[75px] z-[70]">
      <div className="flex justify-between mt-2 gap-2">
        <Tooltip title="Basic">
          <div
            className={`flex items-center gap-2 cursor-pointer w-[70px] h-[70px] border-2 border-[#d5d3cf] rounded-md overflow-hidden shadow-lg ${
              value === 'osm' && 'border-2 border-[#081b37]'
            }`}
            type="default"
            onClick={() => {
              switchToOSM(), setValue('osm');
            }}
          >
            <img src={basic} alt="Basic map" />
          </div>
        </Tooltip>
        <Tooltip title="Satellite">
          <div
            className={`flex items-center gap-2 cursor-pointer w-[70px] h-[70px] border-2 border-[#d5d3cf]  rounded-md overflow-hidden shadow-lg ${
              value === 'satellite' && 'border-2 border-[#081b37]'
            }`}
            type="default"
            onClick={() => {
              switchToSatellite(), setValue('satellite');
            }}
            icon={<PictureOutlined />}
          >
            <img src={satellite} alt="Satellite map" />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default MapStyle;
