import { cloneDeep } from 'lodash';
import { Feature, Map, Overlay, View } from 'ol';
import Circle from 'ol/geom/Circle';
import { defaults, DragPan, Draw, Modify, Select, Snap, Translate } from 'ol/interaction';
import Layer from 'ol/layer/Layer';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat, Projection, transformExtent } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';
//@ts-ignore
import CanvasFilter from 'ol-ext/filter/CanvasFilter';
import proj4 from 'proj4';
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Position, ResizableDelta, Rnd } from 'react-rnd';

import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { fromGeoBoxToBounds } from '../../helpers/boundsManage';
import { getCurrentMapPosition } from '../../helpers/getKeyframes';
import { useHideMapOnLastSkip } from '../../hooks/useHideMapOnLastSkip';
import { useOverlap } from '../../hooks/useOverlap';
import { useScaleFactor } from '../../hooks/useScaleFactor';
import { useWindowResize } from '../../hooks/useWindowResize';
import {
  EARTH_RADIUS,
  MAX_FULLSCREEN_HEIGHT,
  OCEAN_NSPER_COORDINATE,
  SOUTH_POLE_NSPER_COORDINATE,
} from '../../model/constants/constants';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { CustomVectorLayer } from '../../model/definitions/CustomVectorLayer';
import { ForecastWDElementDef } from '../../model/definitions/ForecastWDElementDef';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { ObservedWDElementDef } from '../../model/definitions/ObservedWDElementDef';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { VectorMapLayer } from '../../model/definitions/VecorMapLayer';
import { AnimationsEnum } from '../../model/enums/AnimationsEnum';
import { GlobalPlayerControl } from '../../pages/playground/GlobalPlayerControl';
import { MapsRenderCache } from '../../pages/playground/MapsRenderCache';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef, setMapsRendered } from '../../store/slices/active-slice';
import { selectSkips } from '../../store/slices/selectors';
import { RootState } from '../../store/store';
import { ForecastElement, ObservedElement } from '../canvasElements';
import CityPosterLayerFontContainer from '../canvasElements/CityPosterLayerFontContainer';
import { PosterElement } from '../canvasElements/PosterElement';
import style from '../canvasElements/style.module.scss';
import SymbolLayerFontContainer from '../canvasElements/SymbolLayerContainer';
import { transformPercentToAbsolute } from '../canvasElements/utils';
import { getProjections } from './getProjections';
import {
  addBaseVectorLayer,
  addCustomVectorLayer,
  cityStyle,
  cleanUpCustomVectorLayers,
  createBaseMapTilesURL,
  distance,
  drawCircleGradient,
  getLayerByClassName,
  getOcean,
  handleGraticules,
  renderVectorIsolines,
} from './helpers';
import styles from './MapElement.module.scss';
import nsper from './nsper';
import { SymbolLayerWeatherType } from './SymbolLayerWeatherType';
import Transition from './Transition';
import { useCityPosterLayers } from './useCityPosterLayers';
import useDrawingTools from './useDrawingTools';
import { useEventLayers } from './useEventsLayers';
import { useSymbolLayers } from './useSymbolLayers';
import { useWeatherDataLayers } from './useWeatherDataLayers';

interface MapElementProps {
  canvas: { cnvWidth?: number; cnvHeight?: number };
  panelProps: MapPanelDef;
  onEdit: (e: MapPanelDef) => void;
  canvasRef?: React.MutableRefObject<HTMLDivElement | null>;
  disabled: boolean;
  start?: number;
  end?: number;
  scenesNumber: number;
  sceneId: string;
}

export const GVNSP_MASK_ZINDEX = 1000;

const MapElementBaseMerc = ({
  panelProps,
  onEdit,
  canvasRef,
  canvas,
  disabled,
  start,
  end,
  scenesNumber,
  sceneId,
}: MapElementProps) => {
  const dispatch = useDispatch();

  const shouldRenderMap =
    scenesNumber < 15 ||
    start === undefined ||
    end === undefined ||
    (GlobalPlayerControl.getTime() <= (end || 0) + 3000 &&
      GlobalPlayerControl.getTime() >= (start || 0) - 3000);

  const { bringToFront } = useOverlap(panelProps.positionControl, panelProps.id);
  const mapRefContainer = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<Map | null>(null);
  const filterRef = useRef<any>(null);

  const scaleFactor = useScaleFactor(disabled);
  const hideMap = useHideMapOnLastSkip();
  const skips = useSelector<RootState, SkipTimeDef[]>((state) => selectSkips(state));
  const { cnvHeight } = canvas;

  const [gridPosition] = useState<Position & ResizableDelta>({
    x: panelProps.positionControl.x,
    y: panelProps.positionControl.y,
    width: panelProps.positionControl.w,
    height: panelProps.positionControl.y,
  });
  const [lines] = useState<boolean>(false);

  const {
    activeTime,
    activeAspectRatio,
    mode,
    activeElement,
    oceanMask,
    projectToPlay,
    activeDraw,
    activeFramerate,
  } = useSelector<RootState, ActiveDef>((state) => state.active);

  const isSequence = mode === ModeEnum.SEQUENCE;
  const scene = projectToPlay?.sceneDefs.find((scene) => scene.id === sceneId);
  const sceneTime = projectToPlay.sceneDefs.find((scene) => scene.id === sceneId)?.timeControl;
  const isSceneEnd = panelProps.timeControls[0].endMS + (scene?.hold || 0) === sceneTime?.endMS;
  const run = () => {
    return (
      !hideMap &&
      panelProps.timeControls.find((segment) => {
        return (
          segment.startMS <= contextValue.time &&
          segment.endMS + (isSceneEnd ? scene?.hold ?? 0 : 0) >= contextValue.time
        );
      })
    );
  };
  const isVisible = () => (!run() ? 'hidden' : 'visible');
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);

  const currentLayers = useMemo(() => {
    return panelProps.wdSpace[0].vectorMapLayers ?? [];
  }, [panelProps.wdSpace]);
  const symbolLayers = useMemo(() => {
    return panelProps.wdSpace[0].symbolLayers ?? [];
  }, [panelProps.wdSpace]);

  const projection = panelProps.baseMapSetup?.projection?.name
    ? panelProps.baseMapSetup?.projection?.name
    : 'EPSG:3857';
  const projString =
    panelProps.baseMapSetup.baseMapConfigurationProj4 ||
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs';
  const isMercator = projString.includes('+proj=merc');
  const projectionCalc = `${projection}:${panelProps.id}`;
  const isGVNSP = projectionCalc.includes('ESRI:54049');
  const isUtm = projectionCalc.includes('EPSG:326');

  const projections = getProjections({ center: [0, 0], north: true });
  const projectionSettings = isUtm
    ? projections.find((p) => p.plainCode == 'UTM')
    : projections.find((p) => p.plainCode == projection);

  const projectionLike = useMemo(() => {
    return new Projection({
      code: projectionCalc,
      units: 'm',
      worldExtent: projectionSettings?.worldExtent,
    });
  }, [projectionCalc, projectionSettings]);

  if (projectionSettings) {
    projectionLike.setExtent(projectionSettings.extent);
  }

  const mapUrl = createBaseMapTilesURL(panelProps.baseMapSetup.mapStyle.name);
  const mapPosition = panelProps.mapPositionControl;
  const [coordinates, setCoordinates] = useState<PositionControlDef>({
    x: panelProps.positionControl.x ? panelProps.positionControl.x : 0,
    y: panelProps.positionControl.x ? panelProps.positionControl.y : 0,
    w: panelProps.positionControl.w ? panelProps.positionControl.w : 200,
    h: panelProps.positionControl.h ? panelProps.positionControl.h : 200,
    zindex: panelProps.positionControl.zindex ? panelProps.positionControl.zindex : 0,
    rotation: panelProps.positionControl.rotation ? panelProps.positionControl.rotation : 0,
  });

  const contextValue = useContext(PlayerContext);

  const baseLayer = useMemo(() => {
    if (mapRef.current) {
      const layers = mapRef.current.getLayers().getArray();
      return layers.find((layer) => layer instanceof TileLayer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef.current]);

  useEffect(() => {
    mapRef.current?.updateSize();
    disabled && mapRef.current?.getInteractions().forEach((i) => i.setActive(false));
    !disabled && mapRef.current?.getInteractions().forEach((i) => i.setActive(true));
  }, [cnvHeight, disabled, panelProps.id]);

  /**Initialize map */
  useEffect(() => {
    if (mapRef.current && baseLayer) {
      baseLayer.setVisible(!panelProps.baseMapSetup.hidden);
    }
  }, [panelProps.baseMapSetup.hidden]);
  useEffect(() => {
    if (filterRef.current && panelProps.colorFilters) {
      filterRef.current.set('brightness', panelProps.colorFilters.brightness);
      filterRef.current.set('contrast', panelProps.colorFilters.contrast);
      filterRef.current.set('saturate', panelProps.colorFilters.saturate);
      filterRef.current.set('grayscale', panelProps.colorFilters.grayscale);
      filterRef.current.set('sepia', panelProps.colorFilters.sepia);
      filterRef.current.set('invert', panelProps.colorFilters.invert);
      filterRef.current.set('blur', panelProps.colorFilters.blur);
      filterRef.current.set('hueRotate', panelProps.colorFilters.hueRotate);
    }
  }, [panelProps.colorFilters]);

  useWindowResize(() => {
    mapRef.current?.updateSize();
  }, []);
  useEffect(() => {
    // if (!shouldRenderMap) return;
    const storedMap = MapsRenderCache.get(panelProps.id);
    if (storedMap && shouldRenderMap) {
      /**Remove overlays */
      storedMap
        ?.getOverlays()
        .getArray()
        .filter((o) => !o.getElement()?.className.includes('overlay-weather-type'))
        .forEach((o) => {
          storedMap.removeOverlay(o);
          o.dispose();
        });
      storedMap.getInteractions().forEach((i) => {
        if (
          i instanceof DragPan ||
          i instanceof Select ||
          i instanceof Draw ||
          i instanceof Modify ||
          i instanceof Translate ||
          i instanceof Snap
        ) {
          storedMap.removeInteraction(i);
          i.dispose();
        }
      });
      mapRef.current = storedMap;
      mapRef.current.setTarget(mapRefContainer.current!);
      return;
    }

    // dispatch(resetMapLoading());

    if (!mapRef.current) {
      // @ts-ignore
      proj4.Proj.projections.add(nsper);

      proj4.defs(projectionCalc, projString);
      register(proj4);

      const baseMapSource = new XYZ({
        url: mapUrl + '?app=CDOT',
        crossOrigin: 'anonymous',
        minZoom: isMercator ? undefined : 4,
        cacheSize: Infinity,
        transition: 0,
      });

      const baseLayer = new TileLayer({
        className: 'base-tile-layer',
        source: baseMapSource,
        visible: !panelProps.baseMapSetup.hidden,
      });
      const colorFilter = new CanvasFilter();
      //@ts-ignore
      baseLayer.addFilter(colorFilter);
      filterRef.current = colorFilter;
      const layers: Layer[] = [baseLayer];
      if (isGVNSP) {
        layers.push(
          new VectorLayer({
            className: 'GVNSP_RING',
            zIndex: GVNSP_MASK_ZINDEX,
            source: new VectorSource({
              features: [new Feature(new Circle([0, 0], EARTH_RADIUS))],
            }),
            style: new Style({
              renderer: (coordinates, state) => {
                drawCircleGradient({
                  coordinates,
                  state,
                  fromColor: panelProps.customBackgroundImage?.fromColor || 'black',
                  toColor: panelProps.customBackgroundImage?.toColor || 'black',
                  width: panelProps.customBackgroundImage?.width || 30,
                });
              },
            }),
          }),
        );
      }
      const extCalc =
        isGVNSP && panelProps.properties?.boundingBox
          ? fromGeoBoxToBounds(JSON.parse(panelProps.properties?.boundingBox))
          : panelProps.properties?.boundsWgs
          ? transformExtent(
              JSON.parse(panelProps.properties.boundsWgs),
              'EPSG:4326',
              projectionCalc,
            )
          : panelProps.properties?.boundingBox
          ? fromGeoBoxToBounds(JSON.parse(panelProps.properties?.boundingBox))
          : undefined;

      const mapCenter = fromLonLat([mapPosition!.longitude, mapPosition!.latitude], projectionCalc);

      mapRef.current = new Map({
        maxTilesLoading: Infinity,
        controls: [],
        layers,
        interactions: defaults({
          doubleClickZoom: false,
          dragPan: false,
          mouseWheelZoom: false,
          shiftDragZoom: false,
          pinchZoom: false,
        }),
        view: new View({
          center: mapCenter,
          zoom: mapPosition.zoom ? mapPosition.zoom : 0,
          rotation: 0,
          projection: projectionLike,
          zoomFactor: 2,
          constrainResolution: false,
          smoothExtentConstraint: false,
          extent: extCalc,
        }),
      });

      MapsRenderCache.set(panelProps.id, mapRef.current);

      mapRef.current!.on('rendercomplete', () => {
        if (isGVNSP) {
          const hasCoverUp = Boolean(getLayerByClassName('gvnsp-coverup', mapRef.current!));
          if (hasCoverUp) return;
          const { projectionCenterLat, projectionCenterLon } = panelProps.properties;
          const oceanPointDist = distance(
            Number(projectionCenterLat),
            Number(projectionCenterLon),
            85,
            0,
          );
          const southPolePointDist = distance(
            Number(projectionCenterLon),
            Number(projectionCenterLat),
            -85,
            0,
          );

          let point = OCEAN_NSPER_COORDINATE;
          if (oceanPointDist > southPolePointDist) {
            point = SOUTH_POLE_NSPER_COORDINATE;
          }
          const pixel = mapRef.current?.getPixelFromCoordinate(point)!;

          const baseLayerFound = getLayerByClassName('base-tile-layer', mapRef.current!);
          const imgData = baseLayerFound!.getData(pixel) as Uint8ClampedArray;
          if (!imgData) return;
          const r = imgData[0];
          const g = imgData[1];
          const b = imgData[2];
          const a = (imgData[3] / 255) * 100;

          mapRef.current?.addLayer(
            new VectorLayer({
              className: 'gvnsp-coverup',
              zIndex: -1,
              source: new VectorSource({
                features: [new Feature(new Circle([0, 0], EARTH_RADIUS))],
              }),
              style: new Style({
                fill: new Fill({
                  color: `rgba(${r},${g},${b},${a})`,
                }),
              }),
            }),
          );
        }
      });

      mapRef.current.once('rendercomplete', () => {
        dispatch(setMapsRendered({ mapId: panelProps.id, rendered: true }));
      });
    }

    if (shouldRenderMap) {
      mapRef.current.setTarget(panelProps.id + 'map');
    }
  }, [panelProps.id, shouldRenderMap]);
  useEffect(() => {
    /**Rerender GVNSP Ring */
    if (isGVNSP) {
      const ringLayer = getLayerByClassName('GVNSP_RING', mapRef.current!) as VectorLayer<
        VectorSource<Circle>
      >;
      if (ringLayer) {
        ringLayer.setStyle(
          new Style({
            renderer: (coordinates, state) => {
              drawCircleGradient({
                coordinates,
                state,
                fromColor: panelProps.customBackgroundImage?.fromColor || 'black',
                toColor: panelProps.customBackgroundImage?.toColor || 'black',
                width: panelProps.customBackgroundImage?.width || 30,
              });
            },
          }),
        );
      }
    }
  }, [
    isGVNSP,
    panelProps.customBackgroundImage?.fromColor,
    panelProps.customBackgroundImage?.toColor,
    panelProps.customBackgroundImage?.width,
  ]);
  useEffect(() => {
    /**Remove twice!!! reconsider this */
    return () => {
      mapRef.current
        ?.getOverlays()
        .getArray()
        .filter((o) => !o.getElement()?.className.includes('overlay-weather-type'))
        .forEach((o) => {
          mapRef.current?.removeOverlay(o);
          o.dispose();
        });
    };
  }, []);

  useEffect(() => {
    const oceanUrl = panelProps.oceanMask?.baseMapUrl ? panelProps.oceanMask.baseMapUrl : undefined;
    if (!oceanUrl) return;

    const oceanSource = new XYZ({
      url: oceanUrl,
      crossOrigin: 'anonymous',
      minZoom: isMercator ? undefined : 4,
      cacheSize: Infinity,
      transition: 0,
      projection: projectionLike,
    });
    const allLayers = mapRef.current?.getAllLayers();
    const existingLayer = allLayers?.find((l) => l.getClassName() === 'ocean-layer');
    if (existingLayer && panelProps.oceanMask.hidden) {
      mapRef.current?.removeLayer(existingLayer);
      existingLayer.dispose();
    }
    const oceanLayer = new TileLayer({
      source: oceanSource,
      className: 'ocean-layer',
      zIndex: panelProps.oceanMask?.zindex,
      visible: !panelProps.oceanMask?.hidden,
    });
    if (oceanUrl && !existingLayer && panelProps.oceanMask && !panelProps.oceanMask.hidden)
      mapRef.current?.addLayer(oceanLayer);
  }, [
    isMercator,
    panelProps.oceanMask,
    panelProps.oceanMask?.baseMapUrl,
    panelProps.oceanMask?.hidden,
    panelProps.oceanMask?.inverted,
    projectionLike,
  ]);

  useEffect(() => {
    const allLayers = mapRef.current?.getAllLayers();
    const oceanLayer = allLayers?.find((l) => l.getClassName() === 'ocean-layer');
    if (oceanLayer && oceanLayer.getZIndex() !== panelProps.oceanMask?.zindex) {
      oceanLayer.setZIndex(panelProps.oceanMask?.zindex);
    }
  }, [panelProps.oceanMask?.zindex]);

  /*********************** Overlays (GeoPosters, point data) ***********************/
  const space = panelProps.wdSpace[0];

  const getActiveGeoPosters = useMemo(() => {
    return panelProps.geoPosters; /*?.filter(
      (gp) =>
        gp.enabled &&
        gp.timeControls.some(
          (tc) => tc.startMS <= contextValue.time && tc.endMS > contextValue.time,
        ),
    );*/
  }, [panelProps.geoPosters, contextValue.time]);
  const observedData = useMemo(() => {
    return space.observedDataLayers?.filter(
      (gp) =>
        gp.enabled &&
        gp.timeControls.some(
          (tc) => tc.startMS <= contextValue.time && tc.endMS > contextValue.time,
        ),
    );
  }, [space, contextValue.time]);
  const forecastData = useMemo(() => {
    return space.forecastDataLayers?.filter(
      (gp) =>
        gp.enabled &&
        gp.timeControls.some(
          (tc) => tc.startMS <= contextValue.time && tc.endMS > contextValue.time,
        ),
    );
  }, [space, contextValue.time]);
  function isObserved(obj: any): obj is ObservedWDElementDef {
    return 'observedWDSource' in obj;
  }
  function isForecast(obj: any): obj is ForecastWDElementDef {
    return 'forecastWDSource' in obj;
  }
  useEffect(() => {
    [...getActiveGeoPosters, ...observedData, ...forecastData]?.forEach((overlay) => {
      const el = document.getElementById(overlay.id + panelProps.id);
      if (isObserved(overlay)) {
        const { longitude, latitude } = overlay.observedWDSource.station;
        const isAlreadyAdded = Boolean(mapRef.current?.getOverlayById(overlay.id + panelProps.id));
        if (!isAlreadyAdded)
          mapRef.current?.addOverlay(
            new Overlay({
              element: el!,
              position: fromLonLat([longitude, latitude], projectionCalc),
              id: overlay.id + panelProps.id,
            }),
          );
      } else if (isForecast(overlay)) {
        const { longitude, latitude } = overlay.forecastWDSource.location;
        const isAlreadyAdded = Boolean(mapRef.current?.getOverlayById(overlay.id + panelProps.id));
        if (!isAlreadyAdded)
          mapRef.current?.addOverlay(
            new Overlay({
              element: el!,
              position: fromLonLat([longitude, latitude], projectionCalc),
              id: overlay.id + panelProps.id,
            }),
          );
      } else {
        const { longitude, latitude } = overlay;
        const isAlreadyAdded = Boolean(mapRef.current?.getOverlayById(overlay.id + panelProps.id));
        if (!isAlreadyAdded)
          mapRef.current?.addOverlay(
            new Overlay({
              element: el!,
              position: fromLonLat([longitude, latitude], projectionCalc),
              id: overlay.id + panelProps.id,
            }),
          );
      }
    });
    const existingOverlays = mapRef.current
      ?.getOverlays()
      .getArray()
      .filter((o) => !o.getElement()?.className.includes('overlay-weather-type'));
    if (existingOverlays) {
      existingOverlays.forEach((overlay) => {
        if (
          ![...getActiveGeoPosters, ...observedData, ...forecastData].some(
            (p) => p.id + panelProps.id === overlay.getId(),
          )
        ) {
          mapRef.current?.removeOverlay(overlay);
          overlay.dispose();
        }
      });
    }
  }, [getActiveGeoPosters, observedData, forecastData, space, panelProps.id]);
  /****************** Overlays (GeoPosters, point data) finished ******************/
  useEffect(() => {
    setCoordinates({
      x: panelProps.positionControl.x ? panelProps.positionControl.x : 0,
      y: panelProps.positionControl.y ? panelProps.positionControl.y : 0,
      w: panelProps.positionControl.w ? panelProps.positionControl.w : 0,
      h: panelProps.positionControl.h ? panelProps.positionControl.h : 0,
      zindex: panelProps.positionControl.zindex ? panelProps.positionControl.zindex : 0,
      rotation: panelProps.positionControl.rotation ? panelProps.positionControl.rotation : 0,
    });
  }, [
    panelProps.positionControl.x,
    panelProps.positionControl.y,
    panelProps.positionControl.w,
    panelProps.positionControl.h,
    panelProps.positionControl.zindex,
  ]);

  useEffect(() => {
    if (mapPosition.updateView) {
      mapRef.current
        ?.getView()
        .setCenter(fromLonLat([mapPosition!.longitude, mapPosition!.latitude], projectionCalc));
      mapRef.current?.getView().setZoom(mapPosition.zoom ? mapPosition.zoom : 0);
    }
  }, [
    mapPosition.latitude,
    mapPosition.longitude,
    mapPosition.zoom,
    mapPosition.updateView,
    mapPosition.flyToInitialPosition,
  ]);
  const asyncGraticules = async (onDrag?: boolean) => {
    await handleGraticules(mapRef.current, panelProps, onDrag);
  };

  useEffect(() => {
    mapRef?.current?.on('moveend', () => {
      asyncGraticules(true);
    });
    asyncGraticules();
  }, [
    panelProps?.graticule?.enabled,
    panelProps?.graticule?.strokeColor,
    panelProps?.graticule?.strokeWidth,
    panelProps?.graticule?.longitudeInterval,
    panelProps?.graticule?.latitudeInterval,
    panelProps?.graticule?.zindex,
    panelProps?.graticule?.enableLabels,
  ]);

  useEffect(() => {
    if (!panelProps.flyOverEnabled && mapPosition.updateView) {
      mapRef.current
        ?.getView()
        .setCenter(fromLonLat([mapPosition.longitude, mapPosition.latitude], projectionCalc));
      mapRef.current?.getView().setZoom(mapPosition.zoom);
    }
  }, [panelProps.flyOverEnabled]);

  useEffect(() => {
    if (panelProps.flyOverEnabled) {
      const keyframes = panelProps.flyOver?.keyFrames;
      const sorted = cloneDeep(keyframes!).sort((a, b) => a.timeInMS - b.timeInMS);
      const positionToFly = getCurrentMapPosition(activeTime, sorted, mapPosition);
      if (positionToFly) {
        mapRef.current?.getView().animate({
          center: fromLonLat([+positionToFly?.longitude, +positionToFly?.latitude], projectionCalc),
          zoom: +positionToFly?.zoom,
          duration: 0,
        });
      }
    }
  }, [activeTime, panelProps.flyOverEnabled]);

  useWeatherDataLayers(panelProps, mapRef.current!, contextValue);
  useSymbolLayers(panelProps, mapRef.current!, contextValue);
  useEventLayers(panelProps, mapRef.current!, contextValue);
  useCityPosterLayers(mapRef, panelProps, cityStyle, MAX_FULLSCREEN_HEIGHT, activeAspectRatio);
  useDrawingTools(
    panelProps,
    mapRef.current!,
    contextValue,
    activeAspectRatio,
    mode,
    projectToPlay,
  );

  useEffect(() => {
    renderVectorIsolines(
      panelProps,
      mapRef.current!,
      contextValue,
      mode,
      isSequence ? project : projectToPlay,
      skips,
      activeFramerate,
    );
  }, [panelProps.wdSpace[0].gribMapLayers, contextValue.time, mode, skips, activeFramerate]);

  useEffect(() => {
    if (!mapRef.current) return;
    const existingLayers = mapRef.current
      .getLayers()
      .getArray()
      .filter((l) => l.getClassName()?.includes('base-map-layer-'));
    const currClassNames = currentLayers
      .filter((ly) => ly.enabled)
      .map((l) => `base-map-layer-${l.type}`);
    existingLayers.forEach((el) => {
      if (!currClassNames.includes(el.getClassName())) {
        mapRef.current?.removeLayer(el);
        el.dispose();
      }
    });

    function isCustom(obj: any): obj is CustomVectorLayer {
      return (
        typeof obj === 'object' && obj !== null && 'customVectorDef' in obj && obj.customVectorDef
      );
    }
    function isBase(obj: any): obj is VectorMapLayer {
      return typeof obj === 'object' && obj !== null && 'type' in obj && obj.type;
    }
    currentLayers
      .filter(isBase)
      .filter((ly) => ly.enabled && !ly.customVectorDefTemplate)
      .forEach((l) => {
        addBaseVectorLayer(
          l,
          mapRef.current!,
          projectionLike,
          projString + '&app=CDOT',
          panelProps,
        );
      });
    const customLayersToShow = currentLayers
      .filter(isCustom)
      .filter((ly) => ly.enabled && !!ly.customVectorDef);
    cleanUpCustomVectorLayers(customLayersToShow, mapRef.current!);
    customLayersToShow.forEach((l) => {
      addCustomVectorLayer(l, mapRef.current!, projectionLike, projString + '&app=CDOT');
    });
  }, [currentLayers, panelProps, projString, projectionLike]);

  useEffect(() => {
    const oceanLayer = getOcean(mapRef.current!);
    if (oceanMask) {
      oceanLayer?.setOpacity(1);
    } else {
      oceanLayer?.setOpacity(0);
    }
  }, [oceanMask]);

  const willBeShown = (
    currTime: number,
  ): {
    visible: boolean;
    end: number;
    start: number;
    inAnimationDuration: number;
    outAnimationDuration: number;
    inAnimation: FC<any>;
    outAnimation: FC<any>;
  } => {
    for (let i = 0; i < [panelProps.timeControls].length; i++) {
      if (
        panelProps.timeControls[i].startMS <= currTime &&
        panelProps.timeControls[i].endMS >= currTime
      ) {
        return {
          visible: true,
          end: panelProps.timeControls[i].endMS,
          start: panelProps.timeControls[i].startMS,
          // @ts-ignore
          inAnimation: panelProps.timeControls[i].inAnimationDef,
          // @ts-ignore
          outAnimation: panelProps.timeControls[i].outAnimationDef,
          inAnimationDuration: panelProps.timeControls[i].inAnimationDuration
            ? panelProps.timeControls[i].inAnimationDuration
            : 0,
          outAnimationDuration: panelProps.timeControls[i].outAnimationDuration
            ? panelProps.timeControls[i].outAnimationDuration
            : 0,
        };
      }
    }
    return {
      visible: false,
      end: 0,
      start: 0,
      // @ts-ignore
      inAnimation: AnimationsEnum.CUT,
      // @ts-ignore
      outAnimation: AnimationsEnum.CUT,
      inAnimationDuration: 0,
      outAnimationDuration: 0,
    };
  };

  if (!shouldRenderMap) return null;

  return (
    <>
      <div
        style={{
          visibility: lines ? 'visible' : 'hidden',
          position: 'absolute',
          top: transformPercentToAbsolute(
            gridPosition.y,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
          ),
          left: 0,
          right: 0,
          height: 1,
          borderBottom: '1px dashed white',
        }}
      />
      <div
        style={{
          visibility: lines ? 'visible' : 'hidden',
          position: 'absolute',
          top: 0,
          left: transformPercentToAbsolute(
            gridPosition.x,
            activeAspectRatio,
            'width',
            MAX_FULLSCREEN_HEIGHT,
          ),
          bottom: 0,
          width: 1,
          borderRight: '1px dashed white',
        }}
      />
      <div
        style={{
          visibility: lines ? 'visible' : 'hidden',
          position: 'absolute',
          top:
            transformPercentToAbsolute(
              gridPosition.y,
              activeAspectRatio,
              'height',
              MAX_FULLSCREEN_HEIGHT,
            ) +
            transformPercentToAbsolute(
              coordinates.h,
              activeAspectRatio,
              'height',
              MAX_FULLSCREEN_HEIGHT,
            ),
          left: 0,
          right: 0,
          height: 1,
          borderTop: '1px dashed white',
        }}
      />
      <div
        style={{
          visibility: lines ? 'visible' : 'hidden',
          position: 'absolute',
          left:
            transformPercentToAbsolute(
              gridPosition.x,
              activeAspectRatio,
              'width',
              MAX_FULLSCREEN_HEIGHT,
            ) +
            transformPercentToAbsolute(
              coordinates.w,
              activeAspectRatio,
              'width',
              MAX_FULLSCREEN_HEIGHT,
            ),
          top: 0,
          bottom: 0,
          width: 1,
          borderLeft: '1px dashed white',
        }}
      />
      <Rnd
        id={panelProps.id}
        data-type={'mapPanels'}
        scale={scaleFactor}
        style={{
          zIndex:
            activeDraw.enabled && activeDraw.drawingType
              ? 999
              : activeElement === panelProps.id && bringToFront
              ? 20
              : panelProps.positionControl.zindex,
          textAlign: 'center',
          visibility: isVisible(),
        }}
        disableDragging={true}
        enableResizing={false}
        dragHandleClassName={'MapElement__dragHandle'}
        className={`${style.canvasElement} ${
          mode === ModeEnum.SEQUENCE && !disabled ? style.elementHover : null
        } ${
          activeElement === panelProps.id && mode === ModeEnum.SEQUENCE && !disabled
            ? style.active
            : null
        }`}
        bounds={'parent'}
        size={{
          width: transformPercentToAbsolute(
            coordinates.w,
            activeAspectRatio,
            'width',
            MAX_FULLSCREEN_HEIGHT,
          ),
          height: transformPercentToAbsolute(
            coordinates.h,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
          ),
        }}
        position={{
          x: transformPercentToAbsolute(
            coordinates.x,
            activeAspectRatio,
            'width',
            MAX_FULLSCREEN_HEIGHT,
          ),
          y: transformPercentToAbsolute(
            coordinates.y,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
          ),
        }}
      >
        <Transition
          // @ts-ignore
          inType={willBeShown(contextValue.time).inAnimation}
          inOptions={{
            duration: willBeShown(contextValue.time).inAnimationDuration,
            enterTime: willBeShown(contextValue.time).start,
            currentTime: contextValue.time,
          }}
          // @ts-ignore
          outType={willBeShown(contextValue.time).outAnimation}
          outOptions={{
            duration: willBeShown(contextValue.time).outAnimationDuration,
            exitTime: willBeShown(contextValue.time).end,
            currentTime: contextValue.time,
          }}
        >
          <>
            {getActiveGeoPosters?.map((poster) => (
              <div key={poster.id + panelProps.id}>
                <div id={poster.id + panelProps.id}>
                  <PosterElement
                    sceneId={sceneId}
                    disabled={disabled}
                    key={poster.id}
                    panelProps={poster}
                    isMapOverlay={true}
                    parentMapId={panelProps.id}
                    mapRef={mapRef}
                    parentTime={panelProps.timeControls}
                  />
                </div>
              </div>
            ))}
            {forecastData?.map((poster) => (
              <div key={poster.id + panelProps.id}>
                <div id={poster.id + panelProps.id}>
                  <ForecastElement
                    sceneId={sceneId}
                    key={poster.id}
                    disabled
                    panelProps={poster}
                    isMapOverlay={true}
                    mapId={panelProps.id}
                    canvas={{ cnvWidth: undefined, cnvHeight: undefined }}
                    mapRef={mapRef}
                  />
                </div>
              </div>
            ))}
            {observedData?.map((poster) => (
              <div key={poster.id + panelProps.id}>
                <div id={poster.id + panelProps.id}>
                  <ObservedElement
                    sceneId={sceneId}
                    key={poster.id}
                    panelProps={poster}
                    isMapOverlay={true}
                    mapId={panelProps.id}
                    canvas={{ cnvWidth: undefined, cnvHeight: undefined }}
                  />
                </div>
              </div>
            ))}
            <SymbolLayerWeatherType mapPanel={panelProps} />
            <SymbolLayerFontContainer symbolLayers={symbolLayers} />
            <CityPosterLayerFontContainer cityPosters={panelProps?.cityPosters} />
            <div
              id={panelProps.id + 'map'}
              ref={mapRefContainer}
              draggable={false}
              className={[styles['map-wrap'], isGVNSP ? styles.gvnspBackground : ''].join(' ')}
            />
          </>
        </Transition>
      </Rnd>
    </>
  );
};
export default MapElementBaseMerc;
