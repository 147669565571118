import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { getMultimediaByVersionId } from '../core/api/MultimediaAPI';

export function useMultimediaObjectUrl(versionId: string, isJSON?: boolean) {
  const { data } = useQuery(
    ['IMAGE', versionId],
    () => getMultimediaByVersionId(versionId, isJSON),
    {
      enabled: !!versionId,
    },
  );

  useEffect(() => {
    return () => {
      if (data) URL.revokeObjectURL(data);
    };
  }, [data]);

  return data;
}
