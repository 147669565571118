import { Feature, Map } from 'ol';
import { FeatureLike } from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { useEffect, useRef } from 'react';

import { decimalToHex } from '../../util';
import { eventColors } from '../../variables/nwsAlerts';
import { useGetAlerts } from '../apiNWS/useGetAlerts';

interface UseAlertsLayerProps {
  mapInstanceRef: React.MutableRefObject<Map | null>;
  poolTime: number;
  alertLayer: boolean;
  onFeatureClick: (feature: FeatureLike) => void;
}

// Helper function to update vector source features
const updateVectorSourceFeatures = (source: VectorSource, newFeatures: Feature<Geometry>[]) => {
  const existingFeatures = source.getFeatures();
  const existingFeatureIds = new Set(existingFeatures.map((f) => f.getId()));

  newFeatures.forEach((newFeature) => {
    const newFeatureId = newFeature.getId();

    if (newFeatureId && existingFeatureIds.has(newFeatureId)) {
      const existingFeature = source.getFeatureById(newFeatureId);
      if (existingFeature) {
        existingFeature.setGeometry(newFeature.getGeometry());
        existingFeature.setProperties(newFeature.getProperties());
      }
    } else {
      source.addFeature(newFeature);
    }
  });

  existingFeatures.forEach((existingFeature) => {
    if (!newFeatures.find((f) => f.getId() === existingFeature.getId())) {
      source.removeFeature(existingFeature);
    }
  });
};

export const useAlertsLayer = ({
  mapInstanceRef,
  poolTime,
  alertLayer,
  onFeatureClick,
}: UseAlertsLayerProps) => {
  const { data } = useGetAlerts(poolTime, alertLayer);
  const mainLayerRef = useRef<VectorLayer<VectorSource> | null>(null);
  const zoneLayerRef = useRef<VectorLayer<VectorSource> | null>(null);
  const clickListenerRef = useRef<any>(null);

  useEffect(() => {
    const mapInstance = mapInstanceRef.current;

    if (!alertLayer && mapInstance) {
      if (mainLayerRef.current) mainLayerRef.current.getSource()?.clear();
      if (zoneLayerRef.current) zoneLayerRef.current.getSource()?.clear();
      return;
    }

    if (!data || !data.features || !alertLayer || !mapInstance) return;

    const geoJsonFormat = new GeoJSON();

    const processGeoJSON = async () => {
      const mainFeatures: Feature<Geometry>[] = [];
      const zoneFeatures: Feature<Geometry>[] = [];

      for (const feature of data.features) {
        if (feature.geometry) {
          const mainFeature = geoJsonFormat.readFeature(feature, {
            featureProjection: 'EPSG:3857',
          });
          if (mainFeature) {
            mainFeature.setId(feature.id);
            mainFeature.setProperties({
              ...mainFeature.getProperties(),
              featType: 'NWS',
              region: 'ALERT',
            });
            mainFeatures.push(mainFeature);
          }
        }

        const zoneGeoJSONPromises = feature.properties.affectedZones.map((url: string) =>
          fetch(url).then((res) => res.json()),
        );
        const zoneGeoJSONs = await Promise.all(zoneGeoJSONPromises);
        for (const zoneGeoJSON of zoneGeoJSONs) {
          if (zoneGeoJSON) {
            const zoneFeatureSet = geoJsonFormat.readFeatures(zoneGeoJSON, {
              featureProjection: 'EPSG:3857',
            });
            zoneFeatureSet.forEach((zone) => {
              zone.setId(`${feature.id}-zone`);
              zone.setProperties({
                ...feature.properties,
                ...zone.getProperties(),
                featType: 'NWS',
                region: 'ZONE',
              });
              zoneFeatures.push(zone);
            });
          }
        }
      }

      if (mainLayerRef.current) {
        updateVectorSourceFeatures(mainLayerRef.current.getSource() as VectorSource, mainFeatures);
      } else {
        const mainSource = new VectorSource({ features: mainFeatures });
        const mainLayer = new VectorLayer({
          source: mainSource,
          style: (feature) =>
            new Style({
              fill: new Fill({
                color: `${eventColors[String(feature.getProperties().event)]}${decimalToHex(0.5)}`,
              }),
              stroke: new Stroke({
                color: '#000000',
                width: 1,
              }),
            }),
        });
        mapInstance.addLayer(mainLayer);
        mainLayerRef.current = mainLayer;
      }

      if (zoneLayerRef.current) {
        updateVectorSourceFeatures(zoneLayerRef.current.getSource() as VectorSource, zoneFeatures);
      } else {
        const zoneSource = new VectorSource({ features: zoneFeatures });
        const zoneLayer = new VectorLayer({
          source: zoneSource,
          style: (feature) =>
            new Style({
              stroke: new Stroke({
                color: `${eventColors[String(feature.getProperties().event)]}${decimalToHex(0.5)}`,
                width: 1,
              }),
              fill: new Fill({
                color: `${eventColors[String(feature.getProperties().event)]}${decimalToHex(0.25)}`,
              }),
            }),
        });
        mapInstance.addLayer(zoneLayer);
        zoneLayerRef.current = zoneLayer;
      }

      if (!clickListenerRef.current) {
        clickListenerRef.current = mapInstance.on('singleclick', (event) => {
          mapInstance.forEachFeatureAtPixel(event.pixel, (feature) => {
            if (feature && feature.get('featType') === 'NWS') {
              onFeatureClick(feature);
              return true;
            }
          });
        });
      }
    };

    processGeoJSON().catch((error) => console.error('Error processing GeoJSON data:', error));

    return () => {
      if (mapInstance && mainLayerRef.current) {
        mainLayerRef.current.getSource()?.clear();
      }
      if (mapInstance && zoneLayerRef.current) {
        zoneLayerRef.current.getSource()?.clear();
      }
    };
  }, [data, alertLayer, poolTime, onFeatureClick]);
};
