import useVariantsLoader from '../../core/api/useLoadVariants';

function TextWithFontFamily({ node, element }: any) {
  useVariantsLoader({ name: node.fontFamily, fontVariantId: node.fontVariantId });

  return (
    <span
      style={{
        fontFamily: `${node.fontFamily} ${node.fontType}`,
        textTransform: node.textTransform,
      }}
    >
      {element}
    </span>
  );
}

export default TextWithFontFamily;
