import './styles/antDrawer.scss';

import { AlertOutlined } from '@ant-design/icons';
import { Drawer, InputNumber } from 'antd';
import { FC, memo, useState } from 'react';
import { AiOutlineMenuUnfold } from 'react-icons/ai';

import CameraIcon from '../../../assets/icons/CameraIcon';
import CarsIcon from '../../../assets/icons/CarsIcon';
import RadarIcon from '../../../assets/icons/RadarIcon';
import RoadsIcon from '../../../assets/icons/RoadsIcon';
import SitesIcon from '../../../assets/icons/SitesIcon';
import { NiraSetup } from '../hooks/apiNIRA/useGetNiraVector';
import { NiraControls } from './NIRA/Controls';

interface Props {
  setLayers: (e: Record<string, boolean>) => void;
  layers: Record<string, boolean>;
  niraSetup: NiraSetup;
  niraEdit: (val: NiraSetup) => void;
  frequency: number;
  setFrequency: (val: number) => void;
}

export const LayerSwitch = memo(
  ({ setLayers, layers, niraEdit, niraSetup, frequency, setFrequency }: Props) => {
    const [open, setOpen] = useState(true);

    const toggleDrawer = () => setOpen(!open);

    const LayerButton = ({
      layerKey,
      isActive,
      IconComponent,
      label,
    }: {
      layerKey: string;
      isActive: boolean;
      IconComponent: FC;
      label: string;
    }) => (
      <button
        onClick={() => setLayers({ ...layers, [layerKey]: !isActive })}
        className={`flex pointer-events-auto overflow-hidden items-center cursor-pointer gap-3 px-4 py-2 text-xl text-white rounded-md shadow-sm transition w-full mb-2 ${
          isActive ? 'bg-[#081B37]' : 'bg-[#B5B5B5]'
        }`}
      >
        <span className="anticon ">
          <IconComponent />
        </span>
        <span className="label">{label}</span>
      </button>
    );

    return (
      <div className="absolute z-90 h-screen right-2 top-0 pt-24">
        <Drawer
          closeIcon={false}
          open={true}
          onClose={toggleDrawer}
          mask={false}
          className="!bg-transparent layer-drawer"
          rootStyle={{ boxShadow: 'none' }}
          style={{ boxShadow: 'none', pointerEvents: 'none' }}
          getContainer={false}
          width={open ? 300 : 46} // Adjust the width as needed
        >
          <div className={'relative h-[24px] mb-[10px]'}>
            <div
              style={{
                pointerEvents: 'auto',
                padding: '4px',
                display: 'flex',
                alignItems: 'center',
              }}
              className={
                'rounded-md justify-center w-fit w-[24px] h-[24px] absolute right-0 bg-[#ffffff] border border-[1px] border-[#d5d3cf] rounded-[2px]'
              }
            >
              <AiOutlineMenuUnfold
                onClick={toggleDrawer}
                style={{
                  cursor: 'pointer',
                  color: '#000',
                  fontSize: '24px',
                  transform: open ? 'rotateY(0)' : 'rotateY(180deg)',
                  transition: 'transform .3s',
                }}
              />
            </div>
          </div>
          <>
            <LayerButton
              layerKey="cameras"
              isActive={layers.cameras}
              IconComponent={CameraIcon}
              label="Cameras"
            />
            <LayerButton
              layerKey="roads"
              isActive={layers.roads}
              IconComponent={RoadsIcon}
              label="Roads"
            />
            <LayerButton
              layerKey="vehicles"
              isActive={layers.vehicles}
              IconComponent={CarsIcon}
              label="Vehicles"
            />
            <LayerButton
              layerKey="sites"
              isActive={layers.sites}
              IconComponent={SitesIcon}
              label="Sites"
            />
            <LayerButton
              layerKey="radar"
              isActive={layers.radar}
              IconComponent={RadarIcon}
              label="Radar"
            />
            <div
              className={`pointer-events-auto text-white mb-2 pb-2 rounded-md ${
                layers.alert ? 'bg-[#081B37]' : 'bg-[#B5B5B5]'
              }`}
            >
              <div
                className={`whitespace-nowrap flex pointer-events-auto overflow-hidden items-center cursor-pointer gap-3 px-4 py-2 text-xl text-white rounded-md shadow-sm transition w-full`}
                onClick={() => setLayers({ ...layers, alert: !layers.alert })}
              >
                <AlertOutlined />
                <span className="label">NWS Alerts</span>
              </div>
              <div
                className={`${
                  open ? 'h-full' : 'h-0'
                } grid items-center grid-cols-5 w-[calc(100%-33px)] ml-auto px-4 text-white rounded-b-md overflow-hidden`}
              >
                <div className="col-span-3">Update frequency</div>
                <div className={'col-span-2'}>
                  <InputNumber
                    size="small"
                    suffix={'s'}
                    value={frequency}
                    onChange={(value) => typeof value === 'number' && setFrequency(value)}
                  />
                </div>
              </div>
            </div>
            <NiraControls setup={niraSetup} edit={niraEdit} collapsed={open} />
          </>
        </Drawer>
      </div>
    );
  },
);
LayerSwitch.displayName = 'LayerSwitch';
