import { SymbolLayerDef } from '../../model/definitions/SymbolLayerDef';
import LayerFont from './LayerFont';

const SymbolLayerFontContainer = ({ symbolLayers }: { symbolLayers: SymbolLayerDef[] }) => {
  return (
    <>
      {symbolLayers.map(
        (layer: SymbolLayerDef) =>
          layer.symbolSource?.defaultStyle?.fontFamily && (
            <LayerFont
              key={layer.id}
              fontFamily={layer.symbolSource.defaultStyle.fontFamily}
              fontVariantId={layer.symbolSource.defaultStyle.fontVariantId}
            />
          ),
      )}
    </>
  );
};

export default SymbolLayerFontContainer;
