import { CityGeoPosterDef } from '../../model/definitions/CityGeoPosterDef';
import LayerFont from './LayerFont';

const CityPosterLayerFontContainer = ({ cityPosters }: { cityPosters: CityGeoPosterDef[] }) => {
  return (
    <>
      {cityPosters?.map((cp) =>
        cp?.textPanels?.map(
          (tp) =>
            tp?.fontFamily && (
              <LayerFont
                key={`${tp.fontFamily}-${tp.fontVariantId}`}
                fontFamily={tp.fontFamily}
                fontVariantId={tp.fontVariantId}
              />
            ),
        ),
      )}
    </>
  );
};

export default CityPosterLayerFontContainer;
