import useVariantsLoader from '../../core/api/useLoadVariants';

const LayerFont = ({
  fontFamily,
  fontVariantId,
}: {
  fontFamily?: string;
  fontVariantId?: string;
}) => {
  useVariantsLoader({ name: fontFamily, fontVariantId: fontVariantId });

  return null;
};

export default LayerFont;
