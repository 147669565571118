import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { Feature, Overlay } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Point } from 'ol/geom';
// import { Modify, Select } from 'ol/interaction';
// import Draw from 'ol/interaction/Draw';
// import { SelectEvent } from 'ol/interaction/Select';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import { fromLonLat, toLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { FlatStyleLike } from 'ol/style/flat';
import { StyleLike } from 'ol/style/Style';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';

import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { WeatherDataLoader } from '../../core/weather-data/WeatherDataLoader';
import { FrameLoadingResult } from '../../core/weather-data/WeatherDataLoaderTypes';
import { singleColorOpacity } from '../../helpers/convertOpacity';
import { parseScenes } from '../../helpers/timelineUtil';
import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import { UNITS_DISPLAY } from '../../model/constants/units';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
// import { DataFrameDef } from '../../model/definitions/DataFrameDef';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import {
  SymbolLayerDef,
  SymbolLayerPointDef,
  SymbolSourceType,
  SymbolStyleDef,
} from '../../model/definitions/SymbolLayerDef';
import { AnimationsEnum } from '../../model/enums/AnimationsEnum';
import { UnitDisplayEnum } from '../../model/enums/UnitDisplayEnum';
import { VisualisationTypeEnum } from '../../model/enums/VisualisationTypeEnum';
import PlayerContext, { PlayContext } from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef, setSymbolEditingLayerId } from '../../store/slices/active-slice';
import { setSymbolLayerPoints } from '../../store/slices/project-slice';
import { RootState } from '../../store/store';
import { transformPercentToAbsolute } from '../canvasElements/utils';
import {
  checkShouldRenderFrame,
  getSymbolFramesDataCached,
  getSymbolPointFramesDataCached,
  isTimeInLayersRange,
} from './helpers';
import { callSymbolIconsRerender } from './SymbolLayerWeatherType';

function getAbsoluteFromPercent(percent: number, total: number) {
  return (percent / 100) * total;
}
function getWindSize(value: number, unit: string) {
  if (unit === 'km/h') {
    return value / 3.6;
  }
  if (unit === 'kt') {
    return value / 1.94;
  }
  return value;
}
function getBackgroundColor(unit: string, value: number, fillColor: string) {
  const unitSymbol = UNITS_DISPLAY[unit];
  const coldColor = 'rgb(0, 71, 171, 255)';
  const neutralColor = 'rgb(128, 128, 128, 255)';
  if ((unitSymbol === 'F' && value < 32) || (unitSymbol === 'C' && value < 0)) {
    return coldColor;
  } else if ((unitSymbol === 'F' && value == 32) || (unitSymbol === 'C' && value == 0)) {
    return neutralColor;
  } else {
    return fillColor;
  }
}
// let globalDirtyHack = 0;
export const useSymbolLayers = (
  mapPanel: MapPanelDef,
  map: Map,
  playContext: PlayContext,
  // symbolPointSelectRef: React.MutableRefObject<Select | null>,
  // onSelectSymbolPoint: (props: SelectEvent) => void,
) => {
  const dispatch = useDispatch();
  const {
    symbolEditingLayerId,
    activeFramerate,
    mode,
    projectToPlay,
    activeAspectRatio,
    symbolNewOverrides,
  } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const contextValue = useContext(PlayerContext);
  // const [dirtyHack, setDirtyHack] = useState(0);
  // globalDirtyHack = dirtyHack;

  const symbolLayers = useMemo(
    () => (mapPanel.wdSpace[0].symbolLayers ? mapPanel.wdSpace[0].symbolLayers : []),
    [mapPanel.wdSpace],
  );

  // const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);

  const scene = projectToPlay.sceneDefs.find((sc) =>
    sc.mapPanels.some((p) => p.id === mapPanel.id),
  );
  const sceneStartMs = scene?.timeControl.startMS || 0;
  const sceneHoldMs = scene?.hold || 0;

  useHotkeys(
    'esc',
    (ev) => {
      ev.preventDefault();
      if (symbolEditingLayerId) {
        dispatch(setSymbolEditingLayerId(''));
      }
    },
    [symbolEditingLayerId],
  );

  // 1. Create vector layer for every symbol layer
  // 2. Create interactions when a symbol layer is being edited (and remove them when it isn't)
  // 3. When it stops being edited, save all points and refresh data.. somehow

  const rerender = useCallback(() => {
    if (!map) return;
    rerenderSymbolData(
      map,
      playContext,
      mapPanel,
      mode,
      activeFramerate,
      sceneStartMs,
      sceneHoldMs,
      activeAspectRatio,
      projectToPlay,
      contextValue,
    );
    // I use symbolNewOverrides to trigger a rerender from SymbolPointsOverrideProperties when an override is added
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    playContext,
    map,
    mapPanel,
    mode,
    activeFramerate,
    sceneStartMs,
    activeAspectRatio,
    symbolNewOverrides,
  ]);

  useEffect(() => {
    if (!map) return;
    const weatherTypeLayers = mapPanel.wdSpace[0].symbolLayers.filter(isWeatherTypeLayer);
    const overlayKeys = new Set();
    const allOverlays = map
      .getOverlays()
      .getArray()
      .filter((o) => o.getElement()?.className.includes('overlay-weather-type'));
    const overlaysToRemove: Overlay[] = [];

    weatherTypeLayers
      .filter((l) => l.enabled)
      .forEach((layer) => {
        layer.symbolSource.points.forEach((point) => {
          const key = layer.id + point.lat.toFixed(2) + point.lon.toFixed(2);
          overlayKeys.add(key);
        });
      });
    // Remove overlays that don't belong to any enabled layer
    allOverlays.forEach((overlay) => {
      const key = overlay.getId();
      if (!overlayKeys.has(key)) {
        overlaysToRemove.push(overlay);
      }
    });
    overlaysToRemove.forEach((overlay) => {
      map.removeOverlay(overlay);
      overlay.dispose();
    });
    weatherTypeLayers
      .filter((l) => l.enabled)
      .forEach((layer) => {
        layer.symbolSource.points.forEach((point) => {
          const key = layer.id + point.lat.toFixed(2) + point.lon.toFixed(2);
          const el = document.getElementById(key);
          if (!el) return;
          const existingOverlay = map.getOverlayById(key);
          if (existingOverlay) {
            existingOverlay.setElement(el);
          } else {
            map.addOverlay(
              new Overlay({
                element: el,
                position: fromLonLat([point.lon, point.lat], map.getView().getProjection()),
                id: key,
                className: 'overlay-weather-type',
                positioning: 'center-center',
                stopEvent: false,
              }),
            );
          }
        });
      });
  }, [map, mapPanel.wdSpace, symbolEditingLayerId, symbolLayers, symbolNewOverrides]);

  useEffect(() => {
    if (!map) return;

    const allWDLayers = map.getAllLayers().filter((l) => l.get('symbolLayerId') != undefined);

    const layersToRemove = allWDLayers.map((l) => l.get('symbolLayerId'));

    symbolLayers.forEach((l) => {
      if (!l.enabled) return;
      const hasFrames =
        l.symbolSource.sourceType === SymbolSourceType.PointData
          ? Boolean(l.symbolSource.pointDataFrames?.length)
          : l.symbolSource.sourceType === SymbolSourceType.ModelData
          ? Boolean(l.dataFrames?.length)
          : false;
      if (!hasFrames) return;
      const idx = layersToRemove.findIndex((x) => x === l.id);
      if (idx > -1) layersToRemove.splice(idx, 1);
      ensureLayerExistsOnMap(projectToPlay.id, map, l, mapPanel, rerender, dispatch);
    });

    removeLayersFromMap(map, layersToRemove);
  }, [symbolLayers, map, mapPanel, rerender, dispatch, projectToPlay]);

  // #2 When time changes, rerender all values - from cache, set style of every point based on the point location
  useEffect(() => {
    rerender();
  }, [rerender]);

  // const currentlyInteracting = useRef<string>();
  // const drawInteraction = useRef<Draw>();
  // const modifyInteraction = useRef<Modify>();

  // useEffect(() => {
  //   if (!map) return;
  //   if (symbolEditingLayerId && currentlyInteracting.current != symbolEditingLayerId) {
  //     currentlyInteracting.current = symbolEditingLayerId;
  //     const mapLayer = map
  //       .getAllLayers()
  //       .find((x) => x.get('symbolLayerId') === symbolEditingLayerId) as VectorLayer<
  //       VectorSource<Point>
  //     >;

  //     if (!mapLayer) return;

  //     if (drawInteraction.current) {
  //       drawInteraction.current.finishDrawing();
  //       map.removeInteraction(drawInteraction.current);
  //       drawInteraction.current.dispose();
  //     }

  //     drawInteraction.current = new Draw({
  //       source: mapLayer.getSource()!,
  //       type: 'Point',
  //     });

  //     drawInteraction.current.on('drawend', (e) => {
  //       const feature = e.feature as Feature<Point>;
  //       const points: SymbolLayerPointDef[] = [];
  //       const features = mapLayer.getSource()!.getFeatures();
  //       features.push(feature);

  //       features.forEach((f) => {
  //         const coords = toLonLat(
  //           f.getGeometry()!.getCoordinates(),
  //           map.getView().getProjection()!,
  //         );
  //         points.push({ lon: coords[0], lat: coords[1] });
  //       });

  //       dispatch(setSymbolLayerPoints({ id: symbolEditingLayerId, points }));
  //       dispatch(setNewPoint(true));

  //       const layer = mapPanel.wdSpace[0].symbolLayers.find((x) => x.id === symbolEditingLayerId);
  //       const dataFrames =
  //         layer?.symbolSource.sourceType == SymbolSourceType.PointData
  //           ? layer.symbolSource.pointDataFrames!.map((x) => {
  //               return {
  //                 frameId: x.dateString,
  //               } as DataFrameDef;
  //             })
  //           : layer!.dataFrames;
  //       WeatherDataLoader.clearSelectedFramesCache(layer!.layerType, dataFrames);

  //       setDirtyHack(globalDirtyHack + 1);
  //     });

  //     map.addInteraction(drawInteraction.current);

  //     if (modifyInteraction.current) {
  //       map.removeInteraction(modifyInteraction.current);
  //       modifyInteraction.current.dispose();
  //     }

  //     modifyInteraction.current = new Modify({
  //       source: mapLayer.getSource()!,
  //     });

  //     modifyInteraction.current.on('modifyend', (e) => {
  //       const points: SymbolLayerPointDef[] = [];
  //       e.features.forEach((f) => {
  //         const point = f as Feature<Point>;
  //         const coords = toLonLat(
  //           point.getGeometry()!.getCoordinates(),
  //           map.getView().getProjection()!,
  //         );
  //         points.push({ lon: coords[0], lat: coords[1] });
  //       });

  //       const features = mapLayer.getSource()!.getFeatures();
  //       features.forEach((f) => {
  //         const coords = toLonLat(
  //           f.getGeometry()!.getCoordinates(),
  //           map.getView().getProjection()!,
  //         );
  //         if (!points.find((x) => x.lon === coords[0] && x.lat === coords[1])) {
  //           points.push({ lon: coords[0], lat: coords[1] });
  //         }
  //       });

  //       dispatch(setSymbolLayerPoints({ id: symbolEditingLayerId, points }));
  //       dispatch(setNewPoint(true));

  //       const layer = mapPanel.wdSpace[0].symbolLayers.find((x) => x.id === symbolEditingLayerId);
  //       const dataFrames =
  //         layer?.symbolSource.sourceType == SymbolSourceType.PointData
  //           ? layer.symbolSource.pointDataFrames!.map((x) => {
  //               return {
  //                 frameId: x.dateString,
  //               } as DataFrameDef;
  //             })
  //           : layer!.dataFrames;
  //       WeatherDataLoader.clearSelectedFramesCache(layer!.layerType, dataFrames);

  //       setDirtyHack(globalDirtyHack + 1);
  //     });

  //     map.addInteraction(modifyInteraction.current);
  //   }
  //   if (!symbolEditingLayerId && currentlyInteracting.current) {
  //     // clean up interactions
  //     if (drawInteraction.current) {
  //       drawInteraction.current.finishDrawing();
  //       map.removeInteraction(drawInteraction.current);
  //       drawInteraction.current.dispose();
  //     }
  //     if (modifyInteraction.current) {
  //       map.removeInteraction(modifyInteraction.current);
  //       modifyInteraction.current.dispose();
  //     }
  //     currentlyInteracting.current = '';
  //   }

  //   if (symbolPointSelectRef.current) {
  //     map.removeInteraction(symbolPointSelectRef.current);
  //     symbolPointSelectRef.current.dispose();
  //   }

  //   const selectInteraction = new Select({
  //     hitTolerance: 2,
  //     style: null,
  //     layers: (props) => {
  //       return props.get('symbolLayerId') != undefined;
  //     },
  //   });

  //   map.addInteraction(selectInteraction);

  //   symbolPointSelectRef.current = selectInteraction;

  //   selectInteraction.on('select', onSelectSymbolPoint);
  // }, [symbolEditingLayerId, symbolLayers, map, dispatch, mapPanel, rerender, setDirtyHack]);
};

const removeLayersFromMap = (map: Map, layers: string[]) => {
  if (!layers || !layers.length) return;
  map.getAllLayers().forEach((mapLayer) => {
    if (
      mapLayer.get('symbolLayerId') != undefined &&
      layers.find((x) => x == mapLayer.get('symbolLayerId'))
    ) {
      map.removeLayer(mapLayer);
      mapLayer.dispose();
    }
  });
};

export const isWindLayer = (l: SymbolLayerDef) => {
  return (
    (l.symbolSource.gribSource &&
      l.symbolSource.gribSource.parameterType &&
      l.symbolSource.gribSource.parameterType.visualisation?.defaultType ==
        VisualisationTypeEnum.PARTICLE) ||
    l.symbolSource.pointParameter === 'WindSpeedAndDirection'
  );
};

export const isWeatherTypeLayer = (l: SymbolLayerDef) => {
  return (
    (l.symbolSource.gribSource &&
      l.symbolSource.gribSource.parameterType &&
      l.symbolSource.gribSource.parameterType.name === 'WeatherType') ||
    l.symbolSource.pointParameter === 'WeatherType'
  );
};

const ensureLayerExistsOnMap = (
  projectId: string,
  map: Map,
  layer: SymbolLayerDef,
  mapPanel: MapPanelDef,
  rerender: () => void,
  dispatch: Dispatch<AnyAction>,
) => {
  if (!map) return;
  const mapLayer = map.getAllLayers().find((l) => l.get('symbolLayerId') === layer.id);

  if (!mapLayer) {
    const mapProjection = map.getView().getProjection();
    // create the symbol layer
    const source = new VectorSource({
      features:
        layer.symbolSource.points.map(
          (p) => new Feature(new Point(fromLonLat([p.lon, p.lat], mapProjection))),
        ) || [],
    });

    let style: StyleLike | FlatStyleLike | null | undefined = isWindLayer(layer)
      ? { 'text-value': '-' }
      : {
          'text-value': '-',
          'text-background-fill-color': 'red',
          'text-padding': [6, 4, 5, 6],
          'text-fill-color': '#fff',
        };

    if (isWeatherTypeLayer(layer)) {
      style = null;
    }

    const newMapLayer = new VectorLayer({
      source,
      style,
    });
    newMapLayer.set('symbolLayerId', layer.id);
    map.addLayer(newMapLayer);
  } else {
    // nothing? check points maybe?
  }

  const isModelData = layer.symbolSource.sourceType === SymbolSourceType.ModelData;
  if (layer.symbolSource.points && (layer.symbolSource?.pointDataFrames?.length || isModelData)) {
    const pointsCacheKey = layer.symbolSource.points.flatMap((x) => x.lat + '' + x.lon).join('-');
    if (LAST_LOADED_CACHE[layer.id] != pointsCacheKey) {
      LAST_LOADED_CACHE[layer.id] = pointsCacheKey;
      const worker = WeatherDataLoader.getInstance();
      worker.loadWeatherData(projectId, [layer], mapPanel, true, () => {
        const isPointData = layer.symbolSource.sourceType === SymbolSourceType.PointData;
        if (isPointData) {
          const frameData = WeatherDataLoader.getByFrameId(
            layer.symbolSource.pointDataFrames![0].dateString,
            layer.layerType,
          );

          if (frameData.symbolData) {
            const newPoints =
              frameData.symbolData?.points.map((p) => {
                return {
                  lat: p.lat,
                  lon: p.lon,
                  locationId: p.locationId,
                } as SymbolLayerPointDef;
              }) ?? [];

            dispatch(setSymbolLayerPoints({ id: layer.id, points: newPoints }));
          }
        }
        rerender();
      });
    }
  }
};

const LAST_LOADED_CACHE: Record<string, string> = {};

const basicStyle = new Style({
  text: new Text({
    font: '32px sans-serif',
    backgroundFill: new Fill({
      color: 'red',
    }),
    padding: [6, 6, 6, 6],
    fill: new Fill({
      color: 'white',
    }),
  }),
});

function degreesToRadians(degrees: number) {
  // remove 270 to accept image arrow pointing right
  return ((degrees + 90) * Math.PI) / 180;
}

export const getCurrentSymbolLayerFrame = (
  wdLayer: SymbolLayerDef,
  mode: ModeEnum,
  frameRate: number,
  sceneStartMs: number,
  sceneHoldMs: number,
  playContext: PlayContext,
) => {
  const isPointData = wdLayer.symbolSource.sourceType === SymbolSourceType.PointData;
  const { framesWithTime } = isPointData
    ? getSymbolPointFramesDataCached(
        mode == ModeEnum.SEQUENCE,
        wdLayer,
        // @ts-ignore
        { startMS: sceneStartMs, hold: sceneHoldMs },
        frameRate,
      )
    : getSymbolFramesDataCached(
        mode == ModeEnum.SEQUENCE,
        wdLayer,
        // @ts-ignore
        { startMS: sceneStartMs, hold: sceneHoldMs },
        frameRate,
      );

  return getFirstFrame(playContext.time, wdLayer, framesWithTime);
};

const rerenderSymbolData = (
  map: Map,
  playContext: PlayContext,
  mapPanel: MapPanelDef,
  mode: ModeEnum,
  frameRate: number,
  sceneStartMs: number,
  sceneHoldMs: number,
  activeAspectRatio: [number, number],
  projectToPlay: C9ProjectDef,
  contextValue: PlayContext,
) => {
  if (!mapPanel) return;
  /**Dirty hack to rerender overlays SymbolLayerWeatherType.tsx */
  callSymbolIconsRerender();
  const layers = map
    .getAllLayers()
    .filter((l) => l.get('symbolLayerId') != undefined)
    .map((l) => l as VectorLayer<VectorSource<Point>>);
  layers.forEach((l) => {
    const id = l.get('symbolLayerId');

    const wdLayer = mapPanel.wdSpace[0].symbolLayers.find((x) => x.id === id);
    const isPointData = wdLayer?.symbolSource?.sourceType === SymbolSourceType.PointData;
    if (!wdLayer) {
      console.error('WD Layer not in WD space found symbolLayerId ', id);
      return;
    }
    // Remove the feature if it doesn't exist in wdLayer.symbolSource.points
    l.getSource()?.forEachFeature((f) => {
      f.setId(id);
      const coord = f.getGeometry()?.getCoordinates();
      if (coord) {
        const lonlat = toLonLat(coord, map.getView().getProjection()!);
        if (lonlat) {
          const featureExist = wdLayer.symbolSource.points.find(
            (point) =>
              point.lat.toFixed(2) === lonlat[1].toFixed(2) &&
              point.lon.toFixed(2) === lonlat[0].toFixed(2),
          );
          if (!featureExist) {
            l.getSource()?.removeFeature(f);
          }
        }
      }
    });
    if (isWeatherTypeLayer(wdLayer)) {
      return;
    }

    l.setZIndex(wdLayer.zindex);

    const isSequence = mode === ModeEnum.SEQUENCE;
    const parentSceneId = projectToPlay.sceneDefs.find((sc) =>
      sc.mapPanels.some((p) => p.id === mapPanel?.id),
    )?.id;
    console.assert(parentSceneId && mapPanel, 'NO MAP PANEL');
    if (!parentSceneId) return;
    const simpleScenes = parseScenes(projectToPlay);
    const simpleScene = simpleScenes.find((s) => s.id === parentSceneId)!;
    let fadeOpacity = 1;
    const timeControls = wdLayer.timeControls[0];
    const sceneTimeOffset = isSequence ? 0 : simpleScene?.startMS;
    const currentTime = contextValue.time - sceneTimeOffset;
    if (
      timeControls.inAnimationDef === AnimationsEnum.FADE_IN &&
      currentTime < timeControls.startMS + timeControls.inAnimationDuration
    ) {
      fadeOpacity = (currentTime - timeControls.startMS) / timeControls.inAnimationDuration;
      const finalTime = (timeControls.startMS ?? 0) + timeControls.inAnimationDuration;
      const remaining = finalTime - currentTime;
      fadeOpacity = Math.min(
        Math.max((100 - (remaining * 100) / timeControls.inAnimationDuration) / 100, 0),
        1,
      );
    }
    if (
      timeControls.outAnimationDef === AnimationsEnum.FADE_OUT &&
      currentTime > timeControls.endMS - timeControls.outAnimationDuration
    ) {
      let remaining = (timeControls.endMS ?? 0) - currentTime;
      remaining = (remaining * 100) / timeControls.outAnimationDuration / 100;
      fadeOpacity = Math.min(Math.max(remaining, 0), 1);
    }
    const opacity = wdLayer.opacity * fadeOpacity;
    l.setOpacity(opacity);

    const firstFrame = getCurrentSymbolLayerFrame(
      wdLayer,
      mode,
      frameRate,
      sceneStartMs,
      sceneHoldMs,
      playContext,
    );

    const defaultStyle = wdLayer.symbolSource.defaultStyle ?? new SymbolStyleDef();
    const isWind = isWindLayer(wdLayer);
    // const isWeather = isWeatherTypeLayer(wdLayer);

    const border = new Stroke({
      color: singleColorOpacity(defaultStyle.strokeColor),
      width: getAbsoluteFromPercent(defaultStyle.strokeWidth, MAX_FULLSCREEN_HEIGHT),
    });
    const textColor = new Fill({ color: singleColorOpacity(defaultStyle.fontColor) });
    const fontSize = getAbsoluteFromPercent(
      Number(defaultStyle.fontSize || 1),
      MAX_FULLSCREEN_HEIGHT,
    );
    const font = `${fontSize}px ${defaultStyle.fontFamily} ${defaultStyle.fontType}`;

    const fontStroke = new Stroke({
      color: defaultStyle.fontStrokeColor
        ? singleColorOpacity(defaultStyle.fontStrokeColor)
        : 'transparent',
      width: defaultStyle.fontStrokeWidth
        ? getAbsoluteFromPercent(defaultStyle.fontStrokeWidth, MAX_FULLSCREEN_HEIGHT)
        : 0,
    });

    const paddingDefault = getAbsoluteFromPercent(0.6, MAX_FULLSCREEN_HEIGHT);

    const parseUnit = (display: UnitDisplayEnum, unit?: string) => {
      switch (display) {
        case UnitDisplayEnum.NO_DISPLAY:
          return '';
        case UnitDisplayEnum.UNIT:
          return unit;
        case UnitDisplayEnum.DEGREE:
          return '°';
        case UnitDisplayEnum.DEGREE_UNIT:
          return `°${unit}`;
        default:
          return unit;
      }
    };

    l.getSource()?.forEachFeature((f) => {
      f.setId(id);
      const coord = f.getGeometry()?.getCoordinates();
      if (coord) {
        const lonlat = toLonLat(coord, map.getView().getProjection()!);

        let unit = '';
        let dataClosestPoint = 0;
        let data2ClosestPoint = 0;
        let hasData = false;
        let edited = false;
        if (firstFrame.result && firstFrame.result.symbolData) {
          unit = firstFrame.result.symbolData.unit;
          firstFrame.result.symbolData.points.forEach((p) => {
            if (isCloseEnough(lonlat, p) && p.val && p.val.length > 0 && p.val[0] != null) {
              dataClosestPoint = p.val[0];
              hasData = true;
              if (isWind) {
                data2ClosestPoint = p.val[1];
              }
            }
            if (
              isCloseEnough(lonlat, p) &&
              p.old_val &&
              p.old_val.length > 0 &&
              p.old_val[0] != null
            ) {
              edited = true;
            }
          });
        }
        const value = Number(dataClosestPoint.toFixed(defaultStyle.precision));
        const bg = defaultStyle.dualColor
          ? getBackgroundColor(unit, value, defaultStyle.fillColor)
          : defaultStyle.fillColor;
        const backgroundFill = new Fill({
          color: singleColorOpacity(bg),
        });
        if (isWind) {
          const newTextStyle = basicStyle.getText().clone();
          let unit =
            isPointData && wdLayer.symbolSource.unit
              ? UNITS_DISPLAY[wdLayer.symbolSource.unit]
              : 'm/s'; // m/s is hardcoded for model data
          const windSize = getWindSize(data2ClosestPoint, unit);
          const width =
            wdLayer.symbolSource.defaultStyle.windArrowWidth *
            Math.sqrt(wdLayer.symbolSource.defaultStyle.windArrowScale ?? 1);
          const height =
            wdLayer.symbolSource.defaultStyle.windArrowHeight *
            Math.sqrt(wdLayer.symbolSource.defaultStyle.windArrowScale ?? 1);
          if (!defaultStyle.showUnit) unit = '';
          const textValue = hasData ? Math.round(data2ClosestPoint) + unit : '-';
          newTextStyle.setPadding([paddingDefault, paddingDefault, paddingDefault, paddingDefault]);
          newTextStyle.setText(textValue);
          newTextStyle.setFont(font);
          newTextStyle.setFill(textColor);
          newTextStyle.setBackgroundFill(backgroundFill);
          newTextStyle.setBackgroundStroke(border);
          newTextStyle.setStroke(fontStroke);
          const imageWidth = transformPercentToAbsolute(
            wdLayer.symbolSource.defaultStyle.scaleByWindSpeed ? windSize + 2.5 : width,
            activeAspectRatio,
            'width',
            MAX_FULLSCREEN_HEIGHT,
          );
          const imageHeight = transformPercentToAbsolute(
            wdLayer.symbolSource.defaultStyle.scaleByWindSpeed ? windSize + 2 : height,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
          );
          f.setStyle(
            new Style({
              text: defaultStyle.showWindValue ? newTextStyle : undefined,
              image: defaultStyle.showWindArrow
                ? new Icon({
                    src: wdLayer.symbolSource.defaultStyle.windArrowImage || '/redarrowright.png',
                    rotation: degreesToRadians(dataClosestPoint),
                    width: imageWidth,
                    height: imageHeight,
                  })
                : undefined,
            }),
          );
        } else {
          if (isPointData && unit) unit = UNITS_DISPLAY[unit];
          if (!defaultStyle.showUnit) unit = '';
          const newTextStyle = basicStyle.getText().clone();
          const textValue = hasData
            ? dataClosestPoint.toFixed(defaultStyle.precision) +
              '' +
              parseUnit(defaultStyle.unitDisplay, unit)
            : '-';
          /**TBD CHECK THIS 0 remove bottom padding since it's automaricaly added by openlayers to show letters in font
           * that goes below baseline
           */
          newTextStyle.setPadding([paddingDefault, paddingDefault, 0, paddingDefault]);
          newTextStyle.setText(textValue);
          newTextStyle.setFont(font);
          newTextStyle.setFill(textColor);
          newTextStyle.setBackgroundFill(backgroundFill);
          newTextStyle.setBackgroundStroke(border);
          newTextStyle.setStroke(fontStroke);
          const invisibleDiv = document.createElement('div');
          invisibleDiv.style.position = 'absolute';
          invisibleDiv.style.visibility = 'hidden';
          document.body.appendChild(invisibleDiv);
          invisibleDiv.style.fontSize = fontSize + 'px';
          invisibleDiv.textContent = textValue;
          const textWidth = invisibleDiv.offsetWidth;
          const textHeight = invisibleDiv.offsetHeight;
          document.body.removeChild(invisibleDiv);
          edited
            ? f.setStyle([
                new Style({
                  text: newTextStyle,
                  zIndex: 1,
                }),
                new Style({
                  image: new Icon({
                    src: '/symbolline.svg',
                    width: textWidth,
                    height: 0.05 * textHeight,
                    displacement: [0, -textHeight / 4],
                  }),
                  zIndex: 2,
                }),
              ])
            : f.setStyle(
                new Style({
                  text: newTextStyle,
                }),
              );
        }
      }
    });
  });
};

const isCloseEnough = (c1: Coordinate, c2: SymbolLayerPointDef) => {
  if (c1[0] == c2.lon && c1[1] == c2.lat) return true;
  // eslint-disable-next-line sonarjs/no-collapsible-if
  if (c1[0] === c2.lon || (c1[0] < c2.lon + 0.000001 && c1[0] > c2.lon - 0.000001)) {
    if (c1[1] === c2.lat || (c1[1] < c2.lat + 0.000001 && c1[1] > c2.lat - 0.000001)) {
      return true;
    }
  }
  return false;
};

const getFirstLoadedFrame = (
  wdLayer: SymbolLayerDef,
  frames: {
    startTime: number;
    endTime: number;
    timestamp: number;
    frameId: string;
  }[],
): {
  numberOfLoadedFrames: number;
  firstLoadedFrame: FrameLoadingResult | undefined;
} => {
  let numberOfLoadedFrames = 0;
  let firstLoadedFrame: FrameLoadingResult | undefined;

  for (let i = 0; i < frames.length; i++) {
    const result = WeatherDataLoader.getByFrameId(frames[i].frameId, wdLayer.layerType);
    if (result && result.symbolData) {
      numberOfLoadedFrames++;
      if (!firstLoadedFrame) firstLoadedFrame = result;
    }
    if (numberOfLoadedFrames > 1) {
      break;
    }
  }

  return { numberOfLoadedFrames, firstLoadedFrame };
};

const getFirstFrame = (
  time: number,
  wdLayer: SymbolLayerDef,
  framesWithTime: {
    startTime: number;
    endTime: number;
    timestamp: number;
    frameId: string;
  }[],
): {
  result?: FrameLoadingResult;
  lastingTime: number;
  elapsedTime: number;
} => {
  const frames = framesWithTime;

  const { numberOfLoadedFrames, firstLoadedFrame } = getFirstLoadedFrame(wdLayer, frames);

  if (numberOfLoadedFrames === 1 && isTimeInLayersRange(time, wdLayer.timeControls)) {
    return {
      result: firstLoadedFrame,
      elapsedTime: 1,
      lastingTime: 1,
    };
  }

  for (let i = 0; i < frames.length; i++) {
    if (checkShouldRenderFrame(time, frames[i], wdLayer.timeControls)) {
      const result = WeatherDataLoader.getByFrameId(frames[i].frameId, wdLayer.layerType);
      // These two while loops fix interpolation when the same frame is repeated (when we fill in missing data)
      let startTime = frames[i].startTime;
      let endTime = frames[i].endTime;
      let j = i + 1;
      while (j < frames.length && frames[j].frameId === frames[i].frameId) {
        endTime = frames[j].endTime;
        j++;
      }
      let k = i - 1;
      while (k >= 0 && frames[k].frameId === frames[i].frameId) {
        startTime = frames[k].startTime;
        k--;
      }
      return {
        result,
        elapsedTime: time - startTime,
        lastingTime: endTime - startTime,
      };
    }
  }

  return {
    lastingTime: 0,
    elapsedTime: 0,
  };
};
