import { useKeycloak } from '@react-keycloak/web';
import { InputNumber, Tooltip } from 'antd';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { useContext, useEffect, useState } from 'react';
import { FaBackward, FaForward, FaPlay, FaStop } from 'react-icons/fa';
import { useDispatch } from 'react-redux';

import { useGetRadarValue } from '../../../../core/api/mapLayers/useGetRadarValue';
import { PlaybackEnum } from '../../../../core/ui/enums/PlaybackEnum';
import { loadFrames } from '../../../playground/loadFrames';
import PlayerContext from '../../../playground/playerContext/PlayerContext';
import FrameSelector from './FrameSelector';
import TimeSlider from './TimeSlider';

const PlayerControls = ({ project, setProject }) => {
  const dispatch = useDispatch();
  const {
    keycloak: { idTokenParsed },
  } = useKeycloak();
  const { mutate: getValue, data: radarValues, isLoading: loadingValue } = useGetRadarValue(false);
  const [fromTimestamp, setFromTimestamp] = useState('');
  const [toTimestamp, setToTimestamp] = useState('');
  const [skippedTime, setSkippedTime] = useState({ val: 0, ctrl: false });
  const { isPlaying, setIsPlaying, time, setTime, interval, clear, set, setSkip } =
    useContext(PlayerContext);
  const disabled = !fromTimestamp || !toTimestamp || isPlaying === PlaybackEnum.PLAYING;

  useEffect(() => {
    getValue({
      dataProductId: 'df39baea-672a-4c72-8337-88ff45ba9285',
      location: [-107.12360915278107, -102.78601284721896, 41.423706856445314, 38.256725031704974],
    });
  }, []);

  const formatTimestamp = (timestampInSeconds) => {
    if (!timestampInSeconds) return;
    const timestampInMillis = timestampInSeconds * 1000;
    return moment(timestampInMillis).tz('America/Denver').format('M/D h:mm a');
  };
  const handlePlay = async () => {
    const { sceneDefs, id } = project;
    await Promise.all([loadFrames(sceneDefs, dispatch, idTokenParsed, null, false, id)]);

    let timelineTime = time;
    let startTime = Date.now();
    const startPlay = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime + timelineTime;
      const projectDuration = project.sceneDefs[0].durationInMS;
      setTime(elapsedTime);
      setIsPlaying(PlaybackEnum.PLAYING);
      if (elapsedTime >= projectDuration) {
        setTime(0);
        timelineTime = 0;
        startTime = Date.now();
        setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
        setSkip({ val: 0, ctrl: !skippedTime.ctrl });
      }
    };
    const intv = setInterval(startPlay, 16);

    set(intv);
  };
  const handleStop = () => {
    clear(interval);
    setIsPlaying(PlaybackEnum.PAUSE);
    setTime(time);
    setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
    setSkip({ val: 0, ctrl: !skippedTime.ctrl });
  };

  const handleForward = () => {
    const projectDuration = project.sceneDefs[0].durationInMS;
    const layer = project.sceneDefs[0].mapPanels[0].wdSpace[0].radarMapLayers[0];
    const frameTime = parseInt((projectDuration / layer.dataFrames.length).toFixed(0));
    if (isPlaying === PlaybackEnum.PLAYING) {
      clear(interval);
      time + frameTime < projectDuration
        ? setSkippedTime({ val: frameTime, ctrl: !skippedTime.ctrl })
        : setSkippedTime({
            val: projectDuration - time,
            ctrl: !skippedTime.ctrl,
          });
      time + frameTime < projectDuration
        ? setSkip({ val: frameTime, ctrl: !skippedTime.ctrl })
        : setSkip({
            val: projectDuration - time,
            ctrl: !skippedTime.ctrl,
          });
    } else {
      time + frameTime < projectDuration ? setTime(time + frameTime) : setTime(projectDuration);
      setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
      setSkip({ val: 0, ctrl: !skippedTime.ctrl });
    }
  };

  const handleBackward = () => {
    const projectDuration = project.sceneDefs[0].durationInMS;
    const layer = project.sceneDefs[0].mapPanels[0].wdSpace[0].radarMapLayers[0];
    const frameTime = parseInt((projectDuration / layer.dataFrames.length).toFixed(0));
    if (isPlaying === PlaybackEnum.PLAYING) {
      clear(interval);
      time - frameTime > 0
        ? setSkippedTime({ val: -frameTime, ctrl: !skippedTime.ctrl })
        : setSkippedTime({ val: -time, ctrl: !skippedTime.ctrl });
      time - frameTime > 0
        ? setSkip({ val: -frameTime, ctrl: !skippedTime.ctrl })
        : setSkip({
            val: -time,
            ctrl: !skippedTime.ctrl,
          });
    } else {
      time - frameTime > 0 ? setTime(time - frameTime) : setTime(0);
      setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
      setSkip({ val: 0, ctrl: !skippedTime.ctrl });
    }
  };

  const selectFramesInRange = (frames, fromTimestamp, toTimestamp) => {
    if (!frames || !fromTimestamp || !toTimestamp) return [];
    if (fromTimestamp > toTimestamp) {
      [fromTimestamp, toTimestamp] = [toTimestamp, fromTimestamp];
    }

    return frames.filter(
      (frame) => frame.timestamp >= fromTimestamp && frame.timestamp <= toTimestamp,
    );
  };
  const handleLoopTime = (e) => {
    const value = e * 1000;
    const updatedProject = cloneDeep(project);
    updatedProject.sceneDefs[0].timeControl.endMS = value;
    updatedProject.sceneDefs[0].durationInMS = value;
    updatedProject.sceneDefs[0].mapPanels[0].wdSpace[0].radarMapLayers[0].timeControls[0].endMS =
      value;
    setProject(updatedProject);
    setTime(0);
    setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
    setSkip({ val: 0, ctrl: !skippedTime.ctrl });
    clear(interval);
  };

  const activeFrame = () => {
    const projectDuration = project.sceneDefs[0].durationInMS;
    const layer = project.sceneDefs[0].mapPanels[0].wdSpace[0].radarMapLayers[0];
    const frameTime = parseInt((projectDuration / layer.dataFrames.length).toFixed(0));
    let index = Math.floor(time / frameTime);
    index = Math.min(index, layer.dataFrames.length - 1);
    return layer.dataFrames[index];
  };

  useEffect(() => {
    if (fromTimestamp && toTimestamp) {
      const framesRange = selectFramesInRange(radarValues, fromTimestamp, toTimestamp);
      const updatedProject = cloneDeep(project);
      updatedProject.sceneDefs[0].mapPanels[0].wdSpace[0].radarMapLayers[0].dataFrames =
        framesRange;
      setProject(updatedProject);
      setTime(0);
    }
  }, [fromTimestamp, toTimestamp]);

  const currentFrame = formatTimestamp(activeFrame()?.timestamp);
  return (
    <>
      {!loadingValue && (
        <>
          <div className="flex items-center justify-between text-center text-[14px] text-[#081B37] mb-0 p-[10px_15px]">
            <span className="flex items-center">
              <b className="mr-1">Radar time: </b> {currentFrame ? currentFrame : '-'}
            </span>
            <span className="flex items-center">
              <b className="mr-1">Duration: </b>
              <InputNumber
                size="small"
                type="number"
                max={60}
                className="w-[100px] text-[#081B37] px-2 rounded py-1 text-sm border-[1px] border-[#d5d3cf] disabled:bg-blue-300 disabled:cursor-not-allowed"
                value={project.sceneDefs[0].timeControl.endMS / 1000}
                onChange={handleLoopTime}
                disabled={isPlaying === PlaybackEnum.PLAYING}
                suffix={'sec.'}
              />
            </span>
          </div>
          <hr />
          <div className="relative rounded-md p-4 shadow-lg flex flex-row items-center gap-4">
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-center flex-row gap-2">
                <Tooltip title="-16m">
                  <button
                    onClick={handleBackward}
                    disabled={disabled}
                    className="!flex items-center text-[#081B37] px-4 py-2 circle hover:bg-gray-100 disabled:opacity-30 text-sm"
                  >
                    <FaBackward />
                  </button>
                </Tooltip>
                {isPlaying === PlaybackEnum.PLAYING ? (
                  <button
                    onClick={handleStop}
                    className="flex items-center justify-center bg-[#081B37] text-white px-4 py-2 circle hover:bg-red-600 disabled:bg-green-300 text-sm"
                  >
                    <FaStop />
                  </button>
                ) : (
                  <button
                    onClick={handlePlay}
                    disabled={disabled}
                    className="flex items-center justify-center bg-[#081B37] text-white px-4 py-2 circle hover:bg-green-600 disabled:bg-green-300 text-sm"
                  >
                    <FaPlay />
                  </button>
                )}
                <Tooltip title="+16m">
                  <button
                    onClick={handleForward}
                    disabled={disabled}
                    className="!flex items-center text-[#081B37] px-4 py-2 circle hover:bg-gray-100 disabled:opacity-30 text-sm"
                  >
                    <FaForward />
                  </button>
                </Tooltip>
              </div>
            </div>
            <div className="w-full">
              <FrameSelector
                timestamps={radarValues}
                fromTimestamp={fromTimestamp}
                toTimestamp={toTimestamp}
                setFromTimestamp={setFromTimestamp}
                setToTimestamp={setToTimestamp}
                isPlaying={isPlaying === PlaybackEnum.PLAYING}
              />
              <TimeSlider
                time={time}
                duration={project.sceneDefs[0].durationInMS}
                isPlaying={isPlaying === PlaybackEnum.PLAYING}
                setTime={setTime}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PlayerControls;
