import { RefObject } from 'react';

const translateText = (
  w: number,
  aspectRatio: [number, number],
  time: number,
  ref: RefObject<HTMLDivElement>,
  speed: number,
  startMS: number,
  previewHeight: number,
  direction: string,
): string => {
  const containerWidth = transformPercentToAbsolute(w, aspectRatio, 'width', previewHeight);
  const movement = ((time - startMS) / 100) * speed + containerWidth;
  const crawlWidth = ref.current?.scrollWidth ? ref.current.scrollWidth : 0;
  const repeat = Math.floor(Math.abs(containerWidth - movement) / (containerWidth + crawlWidth));
  return direction === 'right'
    ? `translate(${movement - (containerWidth + crawlWidth) * repeat - containerWidth * 2}px)`
    : `translate(${
        containerWidth - movement + (containerWidth + crawlWidth) * repeat + containerWidth
      }px)`;
};

const transformAbsoluteToPercent = (
  value: number,
  aspectRatio: [number, number],
  direction: 'width' | 'height',
  previewHeight: number,
  canvas?: { w?: number; h?: number },
) => {
  const [aspectW, aspectH] = aspectRatio;
  const noParentWidth = (previewHeight * aspectW) / aspectH;
  const canvasWidth = canvas?.w ?? noParentWidth;
  const canvasHeight = canvas?.h ?? previewHeight;
  switch (direction) {
    case 'width': {
      return parseFloat(((value / canvasWidth) * 100).toFixed(3));
    }
    case 'height': {
      return parseFloat(((value / canvasHeight) * 100).toFixed(3));
    }
  }
};

const transformPercentToAbsolute = (
  percent: number,
  aspectRatio: [number, number],
  direction: 'width' | 'height',
  previewHeight: number,
  canvas?: { w?: number; h?: number },
) => {
  const [aspectW, aspectH] = aspectRatio;
  const noParentWidth = (previewHeight * aspectW) / aspectH;
  const canvasWidth = canvas?.w ?? noParentWidth;
  const canvasHeight = canvas?.h ?? previewHeight;
  switch (direction) {
    case 'width': {
      return parseFloat(((percent / 100) * canvasWidth).toFixed(3));
    }
    case 'height': {
      return parseFloat(((percent / 100) * canvasHeight).toFixed(3));
    }
  }
};

const removeDuplicateIds = (objects: { id?: string }[]): { id?: string }[] => {
  const encounteredIds = new Map<string, number>();

  for (let i = objects.length - 1; i >= 0; i--) {
    const object = objects[i];

    if (Object.prototype.hasOwnProperty.call(object, 'id')) {
      const id = object.id!;

      if (encounteredIds.has(id)) {
        const storedIndex = encounteredIds.get(id)!;
        if (i < storedIndex) {
          objects.splice(i, 1);
        } else {
          encounteredIds.set(id, i);
        }
      } else {
        encounteredIds.set(id, i);
      }
    }
  }

  return objects;
};

export {
  removeDuplicateIds,
  transformAbsoluteToPercent,
  transformPercentToAbsolute,
  translateText,
};
