import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { useSearchParams } from 'react-router-dom';

import { createPlayer } from '../components/timeline/helpers';
import { ModeEnum } from '../core/ui/enums/ModeEnum';
import { parseScenes } from '../helpers/timelineUtil';
import { useHideMapOnLastSkip } from '../hooks/useHideMapOnLastSkip';
import { MAX_FULLSCREEN_HEIGHT } from '../model/constants/constants';
import { C9ProjectDef } from '../model/definitions/C9ProjectDef';
import { MapPanelDef } from '../model/definitions/MapPanelDef';
import { VideoPanelDef } from '../model/definitions/VideoPanelDef';
import { CanvasAudioElement, VideoElement } from '../molecules/canvasElements';
import { VideoOverRecorder } from '../molecules/canvasElements/VideoOverRecorder';
import { VideoOverWrapper } from '../molecules/canvasElements/VideoOverWrapper';
import { MapLoader } from '../molecules/mapElement/MapLoader';
import Loader from '../pages/dashboard/components/Loader';
import styles from '../pages/playground/Playground.module.scss';
import { ActiveDef } from '../store/slices/active-slice';
import { updateMapLayer } from '../store/slices/project-slice';
import { RootState } from '../store/store';
import { SceneElement } from './SceneElement';
import SecondaryControls from './secondaryControls/SecondaryControls';

interface SceneCanvasProps {
  project: C9ProjectDef;
  addElement?: () => void;
  play: () => void;
  pause: () => void;
  toStart: () => void;
  backFive: () => void;
  stop: () => void;
  skipFive: () => void;
  toEnd: () => void;
  skip: () => void;
  prevScene: (e: number) => void;
  nextScene: (e: number) => void;
  active: ActiveDef;
}
const SceneCanvas = ({
  project,
  play,
  pause,
  stop,
  skipFive,
  toEnd,
  backFive,
  toStart,
  skip,
  prevScene,
  nextScene,
  active,
}: SceneCanvasProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const playInitiatedRef = useRef(false);
  const { ref, height } = useResizeDetector();
  const recorder = useSelector<RootState, VideoPanelDef | null>(
    (state) => state.project.present.project?.videoOverRecorder,
  );
  const [params] = useSearchParams();
  const autoPlay = params.get('autoPlay');
  const { projectToPlay, mapsRendered } = active;
  const isMapDefFetching = queryClient.isMutating({ mutationKey: 'addMapElement' }) > 0;
  const [isFullScreen] = useState<boolean>(false);
  const [cdotFullScreen, setCdotFullScreen] = useState(false);

  const aspectRatio = `${active.activeAspectRatio[0]}/${active.activeAspectRatio[1]}`;
  const fullScreenRef = useRef<HTMLDivElement>(null);

  function exitFullScreen() {
    setCdotFullScreen(false);
  }
  const isProjectMode = active.mode === ModeEnum.PROJECT;
  /*  useEffect(() => {
    if (!isProjectMode) dispatch(setMode({ mode: ModeEnum.PROJECT }));
  }, [isProjectMode]);*/

  const hideMapLoader = useHideMapOnLastSkip();
  // Auto play project if first scene maps are rendered
  useEffect(() => {
    if (autoPlay !== 'true' || !projectToPlay || !isProjectMode || playInitiatedRef.current) return;
    const firstScene = projectToPlay?.sceneDefs?.[0];

    if (!firstScene) {
      //  nothing to play
      return;
    }
    const maps = firstScene.mapPanels;

    if (!maps?.length) {
      onPlay();
      playInitiatedRef.current = true;
      return;
    }
    const areAllRendered = maps.every((m) => Boolean(mapsRendered[m.id]));

    if (areAllRendered) {
      onPlay();
      playInitiatedRef.current = true;
    }
  }, [isProjectMode, mapsRendered, projectToPlay, autoPlay]);

  useEffect(() => {
    const abort = new AbortController();
    document.addEventListener(
      'fullscreenchange',
      () => {
        if (!document.fullscreenElement) {
          setCdotFullScreen(false);
          const elScene = document.getElementById('canvas-container');
          if (elScene) {
            elScene.style.transform = `none`;
          }
        } else {
          setCdotFullScreen(true);
        }
      },
      { signal: abort.signal },
    );
    return () => {
      abort.abort();
    };
  }, []);

  const updateSceneMap = useCallback(
    (mapDef: MapPanelDef) => {
      if (isProjectMode) return;
      dispatch(
        updateMapLayer({
          activeScene: active.activeScene,
          elementId: mapDef.id,
          newValue: mapDef.positionControl,
          propertyPath: 'positionControl',
        }),
      );
    },
    [active.activeScene, isProjectMode],
  );

  const renderScene = () => {
    const scene = projectToPlay.sceneDefs?.find((scene) => scene.id === active.activeScene);
    return (
      <SceneElement
        scene={scene}
        key={scene?.id}
        isFullScreen={isFullScreen}
        aspectRatio={active.activeAspectRatio}
        cnvHeight={height!}
        updateMap={updateSceneMap}
      />
    );
  };
  const renderProject = useMemo(() => {
    const stbProject = createPlayer(project, active.syncSpace, active.activeFramerate);
    const simpleScenes = parseScenes(stbProject);

    const scenes = active.projectToPlay?.sceneDefs
      ? stbProject?.sceneDefs.map((scene, index) => (
          <SceneElement
            prevScene={prevScene}
            scene={scene}
            key={scene?.id + index}
            isFullScreen={isFullScreen}
            aspectRatio={active.activeAspectRatio}
            cnvHeight={height!}
            updateMap={updateSceneMap}
            index={index}
            simpleScenes={simpleScenes}
          />
        ))
      : [];
    const voiceover = project.voiceOver ? (
      <CanvasAudioElement
        panelProps={project.voiceOver}
        key={'Project_voiceover'}
        isVoiceOver={true}
      />
    ) : null;
    const videoOverElement = project.videoOver ? (
      <VideoOverWrapper
        positionControl={project.videoOver.positionControl}
        id={project.videoOver.id}
      >
        <VideoElement
          sceneId={''}
          useProxy={false}
          key={project.videoOver.id}
          canvas={{
            cnvWidth: (active.activeAspectRatio[0] / active.activeAspectRatio[1]) * height!,
            cnvHeight: height!,
          }}
          panelProps={project.videoOver}
        />
      </VideoOverWrapper>
    ) : null;
    return [...scenes, voiceover, videoOverElement];
  }, [
    active.activeAspectRatio,
    active.projectToPlay?.sceneDefs,
    active.syncSpace,
    height,
    isFullScreen,
    project.sceneDefs,
    updateSceneMap,
  ]);

  function onPlay() {
    const elScene = document.getElementById('canvas-container');

    if (elScene && !cdotFullScreen) {
      const rect = elScene.getBoundingClientRect();
      const height = rect.height;
      const width = rect.width;
      const scaleHor = window.screen.height / height;
      const scaleVer = window.screen.width / width;
      const scale = Math.min(scaleHor, scaleVer);

      elScene.style.transform = `scale(${scale})`;
      elScene.style.transformOrigin = 'center';
      const el = document.getElementById('Canvas');
      el?.requestFullscreen();
    }

    play();
  }
  return (
    <div
      id={'Canvas'}
      style={{
        position: 'relative',
        height: MAX_FULLSCREEN_HEIGHT,
        aspectRatio,
        width: 'auto',
        margin: 'auto',
      }}
    >
      {(isFullScreen || cdotFullScreen) && <div className="fullScreen_backdrop"></div>}
      <div
        ref={fullScreenRef}
        className={`${isFullScreen ? 'fullScreen_container' : 'noFullScreen_container'}`}
      >
        <div
          className={`${styles.canvasContainer}`}
          style={{
            height: MAX_FULLSCREEN_HEIGHT,
            width: 'auto',
            aspectRatio,
            transformOrigin: 'center',
            transform: cdotFullScreen ? `translate(-50%, -50%)` : ``,
            position: cdotFullScreen ? 'fixed' : 'inherit',
            zIndex: cdotFullScreen ? 99999 : 'inherit',
            top: cdotFullScreen ? '50%' : 'inherit',
            left: cdotFullScreen ? '50%' : 'inherit',
          }}
        >
          <div
            tabIndex={0}
            id="canvas-container"
            className={styles.canvas}
            ref={ref}
            style={{
              width: 'auto',
              height: MAX_FULLSCREEN_HEIGHT,
              aspectRatio,
            }}
          >
            {isMapDefFetching && (
              <div
                key={'canvas-container-map-def-fetching'}
                data-html2canvas-ignore="true"
                className={`absolute w-full h-full z-[9999] flex items-center justify-center bg-neutral-400/50`}
              >
                <Loader />
              </div>
            )}
            {active.mode === ModeEnum.SEQUENCE && renderScene()}
            {isProjectMode && recorder !== null && (
              <VideoOverRecorder
                videoOver={project.videoOverRecorder}
                disabled={isFullScreen}
                play={play}
                key={project.videoOverRecorder?.id}
                canvas={{
                  cnvWidth: (active.activeAspectRatio[0] / active.activeAspectRatio[1]) * height!,
                  cnvHeight: height!,
                }}
              />
            )}
            {isProjectMode && project.videoOver ? (
              <VideoOverWrapper
                positionControl={project.videoOver.positionControl}
                id={project.videoOver.id}
              >
                <VideoElement
                  sceneId={''}
                  canvas={{
                    cnvWidth: (active.activeAspectRatio[0] / active.activeAspectRatio[1]) * height!,
                    cnvHeight: height!,
                  }}
                  isVideoOver
                  useProxy={false}
                  panelProps={project.videoOver}
                />
              </VideoOverWrapper>
            ) : null}
            {isProjectMode && renderProject}

            <SecondaryControls
              prevScene={prevScene}
              nextScene={nextScene}
              passRef={fullScreenRef}
              canvas
              skip={skip}
              fullScreen={isFullScreen}
              backFive={backFive}
              pause={pause}
              skipFive={skipFive}
              start={onPlay}
              stop={stop}
              toEnd={toEnd}
              toStart={toStart}
              onClose={exitFullScreen}
            />
            {!hideMapLoader && <MapLoader />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SceneCanvas;
