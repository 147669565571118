import { cloneDeep } from 'lodash';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Bounce, toast } from 'react-toastify';

import { getProjectDetails } from '../../core/api/ProjectsAPI';
import { getAspectRatioFromExportFormat } from '../../helpers/getAspectRatioFromExportFormat';
import { getFpsFromExportFormat } from '../../helpers/getFpsFromExportFormat';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import {
  setActiveAspectRatio,
  setActiveFrameRate,
  setDisplayedFlyOvers,
  setScene,
} from '../../store/slices/active-slice';
import { addNewProject, setSavedProject } from '../../store/slices/project-slice';

const key = 'LOAD_PROJECT';

export const useLoadProject = (id: string | null) => {
  const dispatch = useDispatch();

  const { data, isLoading } = useQuery(
    [key, id],
    async () => {
      let data: C9ProjectDef | null;
      if (id === 'mock') {
        const response = await fetch('/project.json');
        data = (await response.json()) as C9ProjectDef;
        data.id = 'mock';
      } else {
        data = await getProjectDetails(id!);
      }
      data.sceneDefs.forEach((scene) => {
        if (!scene.timeControl) {
          scene.timeControl = new TimeControlDef(0, scene.durationInMS);
        }
      });
      return data;
    },
    {
      enabled: !!id,
      onSuccess: (result) => {
        const data = cloneDeep(result);
        if (!data.isSuccessfulTimeReference) {
          toast.error('Unable to load the latest project data. Please check the data manually.', {
            className: 'project-toast',
            position: 'top-center',
            autoClose: 10000,
            theme: 'colored',
            transition: Bounce,
          });
        }
        const fps = getFpsFromExportFormat(data.projectExportFormat);
        const aspectRatio = getAspectRatioFromExportFormat(data.projectExportFormat);
        dispatch(addNewProject({ project: data }));
        dispatch(setScene({ activeScene: data.sceneDefs[0].id }));
        dispatch(setSavedProject({ savedProject: data }));
        dispatch(setActiveAspectRatio({ aspectRatio }));
        dispatch(setActiveFrameRate({ fps }));
        data.sceneDefs.forEach((scene) =>
          scene.mapPanels.forEach(
            (map) =>
              map.flyOver?.keyFrames?.length &&
              dispatch(setDisplayedFlyOvers({ displayedFlyOver: map.id })),
          ),
        );
      },
    },
  );
  return { data, isLoading };
};
