import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { WeatherDataMapLayerSetup } from '../../model/definitions/WeatherDataMapLayerSetup';
import { CustomLegend } from '../../pages/playground/properties/components/custom-palette/CustomLegend';
import { ActiveDef } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import PaletteLegend from '../palette/PaletteLegend';
import { getMinMax } from '../palette/utils';
import ElementContainer from './ElementContainer';
import { transformPercentToAbsolute } from './utils';

interface LayerLegendElementProps {
  panelProps: WeatherDataMapLayerSetup & { mapId: string };
  canvas: { cnvWidth?: number; cnvHeight?: number };
  disabled: boolean;
  timeControls: TimeControlDef[];
  mapLevel: number;
}
export const LayerLegendElement = ({
  panelProps,
  canvas,
  timeControls,
  disabled,
  mapLevel,
}: LayerLegendElementProps) => {
  const { activeAspectRatio } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const props = useMemo(
    () => ({
      ...panelProps,
      positionControl: panelProps.paletteLegendPositionControl,
      timeControls,
    }),
    [panelProps, timeControls],
  );

  return (
    <ElementContainer
      canvas={canvas}
      panelProps={{ ...props, timeControls: timeControls }}
      disabled={disabled}
      lock={false}
      // @ts-ignore
      type={'layerLegend'}
      visibility={true}
      parentMapId={props.mapId}
      minLevel={mapLevel}
    >
      {panelProps.colorPaletteDef &&
        (panelProps.colorPaletteDef.setup ? (
          <CustomLegend
            setup={panelProps.colorPaletteDef.setup}
            proportional={panelProps.paletteLegendScaling}
            labelSize={panelProps.legendFontSize}
            fontFamily={panelProps.fontFamily}
            fontType={panelProps.fontType}
            w={transformPercentToAbsolute(
              panelProps.paletteLegendPositionControl.w,
              activeAspectRatio,
              'width',
              MAX_FULLSCREEN_HEIGHT,
            )}
            h={transformPercentToAbsolute(
              panelProps.paletteLegendPositionControl.h,
              activeAspectRatio,
              'height',
              MAX_FULLSCREEN_HEIGHT,
            )}
            values={panelProps.displayLegendValues}
            orientation={panelProps.paletteLegendOrientation}
            cnvHeight={canvas.cnvHeight}
          />
        ) : (
          <PaletteLegend
            width={transformPercentToAbsolute(
              panelProps.paletteLegendPositionControl.w,
              activeAspectRatio,
              'width',
              MAX_FULLSCREEN_HEIGHT,
            )}
            height={transformPercentToAbsolute(
              panelProps.paletteLegendPositionControl.h,
              activeAspectRatio,
              'height',
              MAX_FULLSCREEN_HEIGHT,
            )}
            orientation={panelProps.paletteLegendOrientation}
            values={panelProps.displayLegendValues}
            palette={panelProps.colorPaletteDef}
            visualisationType={panelProps.visualisationType}
            intervalSize={panelProps.colorPaletteDef.colorStops?.interval}
            min={getMinMax(panelProps.colorPaletteDef).min}
            max={getMinMax(panelProps.colorPaletteDef).max}
          />
        ))}
    </ElementContainer>
  );
};
