import './button.scss';

import { ButtonHTMLAttributes, cloneElement, ForwardedRef, ReactElement } from 'react';

import { Color } from '../../types/Color';
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: 'primary' | 'secondary' | 'link' | 'border' | 'danger';
  type?: 'button' | 'submit' | 'reset';
  size?: 'small' | 'medium' | 'large';
  label?: any;
  icon?: ReactElement;
  disabled?: boolean;
  shape?: 'default' | 'circle' | 'round' | 'link';
  color?: Color;
  iconColor?: Color;
  iconSize?: number;
  loading?: boolean;
  ref?: ForwardedRef<HTMLButtonElement>;
}

const Button = ({
  buttonType = 'primary',
  size = 'medium',
  label,
  icon,
  shape = 'default',
  color,
  disabled = false,
  iconColor,
  iconSize,
  loading,
  ...props
}: ButtonProps) => {
  const mode = `button-${buttonType}`;
  const buttonIcon =
    icon &&
    cloneElement(icon, {
      color: iconColor ? iconColor : color,
      size: iconSize,
    });
  const className = props.className;
  delete props.className;
  return (
    <button
      disabled={disabled || loading}
      style={{ color }}
      className={['button', `button-${size}`, mode, shape, className].join(' ')}
      {...props}
    >
      {loading ? <LoadingIndicator /> : buttonIcon}
      <span className={buttonIcon && label ? 'label' : ''}>{label}</span>
    </button>
  );
};
export default Button;
