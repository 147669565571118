export enum ElementsEnum {
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
  SEQUENCE = 'SEQUENCE',
  SCENE = 'SCENE',
  MAP = 'MAP',
  WEATHER_GRAPH = 'WEATHER_GRAPH',
  POSTER = 'POSTER',
  POINT_DATA = 'POINT_DATA',
  OBSERVED_WD = 'OBSERVED_WD',
  FORECAST_WD = 'FORECAST_WD',
  POINT_DATE = 'POINT_DATE',
  POINT_LOCATION = 'POINT_LOCATION',
  GROUP = 'GROUP',
  LAYER = 'LAYER',
  WD_SPACE = 'WD_SPACE',
  TEMPLATE = 'TEMPLATE',
  INDICATOR = 'INDICATOR',
  DRAWING = 'DRAWING',
  GEOPOSTER = 'GEOPOSTER',
  ANIMATION = 'ANIMATION',
}
