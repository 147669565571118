import './styles/style.css';

import { Card } from 'antd';
import { useState } from 'react';

import AlertIcon from '../../assets/icons/AlertIcon';
import MapIcon from '../../assets/icons/MapIcon';
// import MapStyleIcon from '../../assets/icons/MapStyleIcon';
import SegmentIcon from '../../assets/icons/SegmentIcon';
import TableIcon from '../../assets/icons/TableIcon';
import TimeIcon from '../../assets/icons/Time';
import logo from '../../assets/images/logo.png';
import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import { GlobalPlayerControl } from '../playground/GlobalPlayerControl';
import { PlayerProvider } from '../playground/playerContext/PlayerContext';
import Alert from './components/Alert';
import DateTime from './components/Datetime';
import Map from './components/Map';
import { MapLoader } from './components/MapLoader';
import { placeholder } from './components/radar/placeholder';
import PlayerControls from './components/radar/Player';
import RoadsTable from './components/RoadsTable';
import SiteTable from './components/SiteTable';
import Time from './components/Time';
import VehiclesTable from './components/VehiclesTable';

const timeOptions = [
  { label: 'Now', value: 'obs' },
  { label: 'Next 6 Hrs', value: 'hr06' },
  { label: '6 - 24 Hrs', value: 'hr24' },
  { label: '24 - 72 Hrs', value: 'hr72' },
];

const alertOptions = [
  { label: 'All', value: 'all' },
  { label: 'Pavement Condition', value: 'pavement_condition' },
  { label: 'Precipitation', value: 'precip' },
  { label: 'Visibility', value: 'visibility' },
  { label: 'Blow Over', value: 'blow_over' },
  { label: 'Treatment', value: 'treatment' },
];

const PikAlert = () => {
  const [project, setProject] = useState(placeholder);
  const [tab, setTab] = useState('map');
  const [controlOverlay, setControlOverlay] = useState('');
  const [time, setTime] = useState(timeOptions[1]);
  const [alert, setAlert] = useState(alertOptions[0]);
  const [playback, setPlayback] = useState(PlaybackEnum.STOP);
  const [playTime, setPlayTime] = useState(0);
  const [site, setSite] = useState(null);
  const [skip, setSkip] = useState({ val: 0, ctrl: true });
  const [interval, setIntervalId] = useState(null);
  const [layers, setLayers] = useState({
    cameras: true,
    vehicles: true,
    sites: true,
    roads: true,
    nira: true,
    alert: true,
    radar: true,
  });

  const playerContextValue = {
    skip,
    setSkip,
    time: playTime,
    setTime: (e) => {
      setPlayTime(e);
      GlobalPlayerControl.setTime(e);
    },
    isPlaying: playback,
    setIsPlaying: setPlayback,
    interval: 0,
    set: setIntervalId,
    clear: () => clearInterval(interval),
  };

  return (
    <PlayerProvider value={playerContextValue}>
      <div className="h-full">
        {tab === 'map' && (
          <>
            <Map
              project={project}
              layers={layers}
              setLayers={setLayers}
              mapStyleCard={controlOverlay === 'mapStyle'}
              alert={alert.value}
              time={time.value}
              setSite={setSite}
            />
            {controlOverlay === 'time' && (
              <CardOverlay title="TIME" topOffset={70}>
                <Time options={timeOptions} time={time} setTime={setTime} />
              </CardOverlay>
            )}
            {controlOverlay === 'alert' && (
              <CardOverlay title="ALERTS" topOffset={130}>
                <Alert options={alertOptions} selectedValue={alert} onChange={setAlert} />
              </CardOverlay>
            )}
            <div className={`${layers.radar ? 'block' : 'hidden'}`}>
              <PlayerControlsOverlay>
                <PlayerControls project={project} setProject={setProject} />
              </PlayerControlsOverlay>
            </div>
            <DateTime />
          </>
        )}
        {tab === 'road' && (
          <RoadsTable
            alert={alert}
            options={alertOptions}
            onChange={setAlert}
            handleSiteClick={setSite}
            setTab={setTab}
          />
        )}
        {tab === 'vehicles' && <VehiclesTable setTab={setTab} />}
        <SiteTable data={site} onClose={() => setSite(null)} site={site?.site_num} />
        <SidebarMenu
          tab={tab}
          setTab={setTab}
          controlOverlay={controlOverlay}
          setControlOverlay={setControlOverlay}
        />
      </div>
      <MapLoader />
    </PlayerProvider>
  );
};

const SidebarMenu = ({ tab, setTab, controlOverlay, setControlOverlay }) => {
  const switchOverlayCard = (overlay) => {
    if (overlay === controlOverlay) {
      setControlOverlay(undefined);
    } else {
      setControlOverlay(overlay);
    }
  };
  return (
    <div className="fixed top-2 bottom-2 left-2 bg-white p-1 md:p-2 rounded shadow-md z-10 flex items-start justify-between flex-col gap-4 left-menu z-[99]">
      <div>
        <img src={logo} alt="Logo" className="w-[35px]" />
        <div className="flex flex-col gap-4 pt-6 h-[270px]">
          {tab === 'map' && (
            <MenuItem
              icon={<TimeIcon />}
              label="Time"
              active={controlOverlay === 'time'}
              onClick={() => switchOverlayCard('time')}
            />
          )}
          {tab === 'map' && (
            <MenuItem
              icon={<AlertIcon />}
              label="Alerts"
              active={controlOverlay === 'alert'}
              onClick={() => switchOverlayCard('alert')}
            />
          )}
          {/* {tab === 'map' && (
            <MenuItem
              icon={<MapStyleIcon />}
              label="Map style"
              active={controlOverlay === 'mapStyle'}
              onClick={() => switchOverlayCard('mapStyle')}
            />
          )} */}
        </div>
      </div>
      <div className="flex flex-col gap-4 h-[270px]">
        <MenuItem
          icon={<MapIcon />}
          label="Map"
          active={tab === 'map'}
          onClick={() => setTab('map')}
        />
        <MenuItem
          icon={<SegmentIcon />}
          label="Road Segments/RWIS"
          active={tab === 'road'}
          onClick={() => setTab('road')}
        />
        <MenuItem
          icon={<TableIcon />}
          label="Vehicles"
          active={tab === 'vehicles'}
          onClick={() => setTab('vehicles')}
        />
      </div>
      <div className="mb-[50px] h-[170px]"></div>
    </div>
  );
};

const MenuItem = ({ icon, label, active, onClick }) => (
  <button
    className={`px-2 py-2 rounded-lg text-black itm ${
      active
        ? 'text-[#081B37] border-[1px] border-[#081B37]'
        : 'text-[#081B37] border border-[transparent]'
    }`}
    onClick={onClick}
  >
    <div className="flex items-center gap-2">
      {icon} <span>{label}</span>
    </div>
  </button>
);

const CardOverlay = ({ title, topOffset, children }) => (
  <div className={`absolute bg-transparent left-[75px] z-40`} style={{ top: topOffset }}>
    <Card
      size="small"
      title={<span className="font-semibold">{title}</span>}
      style={{ width: 300 }}
    >
      {children}
    </Card>
  </div>
);

const PlayerControlsOverlay = ({ children }) => (
  <div className="absolute bg-white text-sm md:text-base bottom-2 left-1/2 -translate-x-1/2 z-[70] rounded-md shadow-lg w-[600px]">
    {children}
  </div>
);

export default PikAlert;
