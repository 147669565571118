import { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Text } from 'slate';
import { v4 } from 'uuid';

import { gradientOpacity, singleColorOpacity } from '../../helpers/convertOpacity';
import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import { BorderDef } from '../../model/definitions/BorderDef';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { RichTextChildDef } from '../../model/definitions/RichTextChildDef';
import { TextPanelDef } from '../../model/definitions/TextPanelDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import ElementContainer from './ElementContainer';
import TextWithFontFamily from './TextWithFontFamily';
import { transformPercentToAbsolute, translateText } from './utils';

interface TextElementElementProps {
  panelProps: TextPanelDef;
  canvas: { cnvWidth?: number; cnvHeight?: number };
  disabled: boolean;
  isMapTextElement?: boolean;
  mapId?: string;
  parentTime?: TimeControlDef[];
  inPoster?: boolean;
  posterId?: string;
  parentSize?: PositionControlDef;
  isMapOverlay?: boolean;
  geoPosterId?: string;
  sceneId: string;
}

export const TextElement = ({
  panelProps,
  canvas,
  disabled,
  mapId,
  parentTime,
  inPoster,
  posterId,
  parentSize,
  isMapOverlay = false,
  sceneId,
}: TextElementElementProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { activeAspectRatio, translation, activeScene, sceneTranslation } = useSelector<
    RootState,
    ActiveDef
  >((state) => state.active);
  const {
    value,
    boxDef,
    fontSize,
    richText,
    fontColor,
    fontAlignment,
    textAnimation,
    timeControls,
    positionControl,
    strokeWidth,
    strokeColor,
  } = panelProps;
  const {
    paddingBottom,
    paddingTop,
    paddingLeft,
    paddingRight,
    background,
    borderRight,
    borderTop,
    borderLeft,
    borderBottom,
  } = boxDef;
  const { cnvHeight } = canvas;
  let fontUnit: number;
  if (inPoster && cnvHeight) {
    const cH = transformPercentToAbsolute(
      cnvHeight,
      activeAspectRatio,
      'height',
      MAX_FULLSCREEN_HEIGHT,
    );
    fontUnit = cH / 100;
  } else {
    fontUnit = (cnvHeight ?? MAX_FULLSCREEN_HEIGHT) / 100;
  }
  const { color } = background;
  const colorBgOpacity = color?.includes('linear-gradient')
    ? gradientOpacity(color)
    : singleColorOpacity(color);
  const backgroundClip = fontColor?.includes('linear') ? 'text' : 'initial';
  const bgColor = fontColor?.includes('linear-gradient')
    ? gradientOpacity(fontColor)
    : colorBgOpacity;
  const contextValue = useContext(PlayerContext);
  const { time } = contextValue;
  const borderString = (val: BorderDef) =>
    val && `${fontUnit * val.width}px ${val.style} ${singleColorOpacity(val.color)}`;
  const dict = sceneTranslation[activeScene]?.customWords || translation?.customWords;

  const richTextDisplay = (node: any) => {
    if (Text.isText(node)) {
      let element = stringWithPlaceholders(node.text, dict);
      // @ts-ignore
      if (node.underline) {
        // @ts-ignore
        element = <u>{element}</u>;
      }
      // @ts-ignore
      if (node.superscript) {
        // @ts-ignore
        element = <sup>{element}</sup>;
      }
      // @ts-ignore
      if (node.subscript) {
        // @ts-ignore
        element = <sub>{element}</sub>;
      }
      // @ts-ignore
      if (node.fontFamily) {
        // @ts-ignore
        element = <TextWithFontFamily key={v4()} node={node} element={element} />;
      }
      return element;
    }
    return node.children.map((n: RichTextChildDef) => richTextDisplay(n));
  };
  function stringWithPlaceholders(inputString: string, valueObject: Record<string, string> = {}) {
    const regex = /\$(\w+)/g;
    return inputString?.replace(regex, (match, placeholder) => {
      return (valueObject && valueObject[placeholder]) || match;
    });
  }
  return (
    <ElementContainer
      visibility={panelProps.enabled}
      canvas={canvas}
      panelProps={panelProps}
      disabled={disabled}
      type={'textPanels'}
      lock={false}
      sceneId={sceneId}
      style={{
        color: fontColor?.includes('linear') ? 'transparent' : singleColorOpacity(fontColor),
        textAlign: fontAlignment as FontAlignment,
        fontSize: fontUnit * fontSize,
      }}
      parentTime={parentTime}
      inPoster={inPoster}
      posterId={posterId}
      parentSize={parentSize}
      isMapOverlay={isMapOverlay}
      parentMapId={mapId}
    >
      <div
        className="text-panel-container"
        key={fontColor + 'container'}
        style={{
          height: '100%',
          overflow: 'hidden',
          background: bgColor,
          paddingTop: fontUnit * paddingTop,
          paddingLeft: fontUnit * paddingLeft,
          paddingRight: fontUnit * paddingRight,
          paddingBottom: fontUnit * paddingBottom,
          borderLeft: borderString(borderLeft),
          borderRight: borderString(borderRight),
          borderTop: borderString(borderTop),
          borderBottom: borderString(borderBottom),
          // @ts-ignore
          WebkitBackgroundClip: backgroundClip,
        }}
      >
        <div style={{ overflow: 'hidden', width: '100%' }}>
          <div
            style={{
              height: '100%',
              minWidth: textAnimation.active ? '100%' : 'auto',
              width: textAnimation.active ? 'fit-content' : 'auto',
              whiteSpace: textAnimation.active ? 'nowrap' : 'break-spaces',
              /*** I don't know the reason behind fixing margin and line height, but it is causing issues ***/
              /*
              marginTop: fontSize * 1.15, */
              lineHeight: 1.15,
              // @ts-ignore
              WebkitBackgroundClip: backgroundClip,
              transform: textAnimation?.active
                ? translateText(
                    positionControl.w,
                    activeAspectRatio,
                    time,
                    ref,
                    textAnimation.speed,
                    timeControls[0].startMS,
                    MAX_FULLSCREEN_HEIGHT,
                    textAnimation.direction,
                  )
                : 'none',
              WebkitTextStroke: strokeWidth
                ? `${strokeWidth}px ${singleColorOpacity(strokeColor)}`
                : '0px',
            }}
            ref={ref}
          >
            {richText ? richTextDisplay({ ...richText }) : stringWithPlaceholders(value, dict)}
          </div>
        </div>
      </div>
    </ElementContainer>
  );
};
