import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';

import { FontVariantInterface } from '../../pages/playground/properties/panels/FontProperties';
import { axiosInstancePublic } from './axiosInstancePublic';
import { useGetAllVariants } from './useGetAllVariants';

interface FontVariant {
  name?: string;
  fontVariantId?: string;
}

const fontCache: Record<string, boolean> = {};
const variantCache: Record<string, Blob | null> = {};
const ongoingFetches: Record<string, Promise<Blob | null> | null> = {};

const groupVariantsByName = (
  variants:
    | {
        fontVariant?: FontVariantInterface;
        name?: string;
      }[]
    | undefined,
) => {
  if (!variants) return;

  return variants.reduce(
    (acc: Record<string, { name: string; fontVariants: FontVariantInterface[] }>, variant) => {
      const { name, fontVariant } = variant;

      if (!name || !fontVariant) return acc;

      if (acc[name]) {
        acc[name].fontVariants.push(fontVariant);
      } else {
        acc[name] = { name, fontVariants: [fontVariant] };
      }

      return acc;
    },
    {},
  );
};

const useVariantsLoader = (fontVariants: FontVariant | FontVariant[]) => {
  const normalizedFontVariants = Array.isArray(fontVariants) ? fontVariants : [fontVariants];

  const { data: loadedFontVariants } = useGetAllVariants(normalizedFontVariants);

  const groupedFontVariants = useMemo(() => {
    if (!loadedFontVariants) return undefined;
    //@ts-ignore
    return Object.values(groupVariantsByName(loadedFontVariants));
  }, [loadedFontVariants]);

  useEffect(() => {
    if (groupedFontVariants) {
      loadFonts(groupedFontVariants);
    }
  }, [groupedFontVariants]);
};

async function fetchFontVariant(variant: FontVariantInterface) {
  if (variantCache[variant.id]) {
    return variantCache[variant.id];
  }

  if (ongoingFetches[variant.id] !== undefined) {
    return ongoingFetches[variant.id];
  }

  const fetchPromise = axiosInstancePublic
    .get(`font-variant/ttf/${variant.id}`, {
      responseType: 'blob',
    })
    .then((response) => {
      variantCache[variant.id] = response.data;
      return response.data;
    })
    .catch((error) => {
      toast.error(`Error fetching font variant: ${variant.id}`);
      throw error;
    })
    .finally(() => {
      delete ongoingFetches[variant.id];
    });

  ongoingFetches[variant.id] = fetchPromise;

  return fetchPromise;
}

async function loadFonts(
  fontVariants: {
    fontVariants: FontVariantInterface[];
    name: string;
  }[],
) {
  if (!Array.isArray(fontVariants)) {
    return;
  }

  for (const fV of fontVariants) {
    const fontFaceName = fV.name;

    try {
      const fontPromises = fV.fontVariants.map(async (fontVariant: FontVariantInterface) => {
        const fontBlob = await fetchFontVariant(fontVariant);
        const fontUrl = URL.createObjectURL(fontBlob);

        const fontFace = new FontFace(`${fontFaceName} ${fontVariant.type}`, `url(${fontUrl})`);

        const fullFontName = `${fontFaceName} ${fontVariant.type}`;
        const existingFontFace = Array.from(document.fonts).some(
          (font: FontFace) => font.family === fullFontName,
        );

        if (!existingFontFace) {
          document.fonts.add(fontFace);
          await fontFace.load();
        }

        URL.revokeObjectURL(fontUrl);

        fontCache[fV.name] = true;
      });

      await Promise.all(fontPromises);
    } catch (error) {
      toast.error(`Error loading font: ${fV.name}`);
    }
  }
}

export default useVariantsLoader;
