import './style.scss';

import { FC, Fragment, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { animate } from '../animations/animate';
import { SceneSimple } from '../components/timeline/Timeline';
import { ModeEnum } from '../core/ui/enums/ModeEnum';
import { getAnimationType } from '../helpers/timelineUtil';
import { ForecastWDElementDef } from '../model/definitions/ForecastWDElementDef';
import { MapPanelDef } from '../model/definitions/MapPanelDef';
import { ObservedWDElementDef } from '../model/definitions/ObservedWDElementDef';
import { PointDateDef } from '../model/definitions/PointDateDef';
import { PointLocationDef } from '../model/definitions/PointLocationDef';
import { SceneDef } from '../model/definitions/SceneDef';
import { TextPanelDef } from '../model/definitions/TextPanelDef';
import { TimeControlDef } from '../model/definitions/TimeControlDef';
import { WeatherDataSpaceDef } from '../model/definitions/WeatherDataSpaceDef';
import { AnimationsEnum } from '../model/enums/AnimationsEnum';
import {
  CanvasAudioElement,
  ForecastElement,
  ImageElement,
  ObservedElement,
  TextElement,
  VideoElement,
} from '../molecules/canvasElements';
import { AnimationElement } from '../molecules/canvasElements/AnimationElement';
import { IndicatorElement } from '../molecules/canvasElements/IndicatorElement';
import { LayerLegendElement } from '../molecules/canvasElements/LayerLegendElement';
import { PointDateElement } from '../molecules/canvasElements/PointDateElement';
import { PointLocationElement } from '../molecules/canvasElements/PointLocationElement';
import { PosterElement } from '../molecules/canvasElements/PosterElement';
import { PosterMarker } from '../molecules/canvasElements/PosterMarker';
import { getAllLayerSetupsInScene } from '../molecules/mapElement/helpers';
import MapElement from '../molecules/mapElement/MapElementBaseMerc';
import PlayerContext from '../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../store/slices/active-slice';
import { RootState } from '../store/store';

interface SceneElementProps {
  scene?: SceneDef;
  isFullScreen: boolean;
  aspectRatio: [number, number];
  cnvHeight: number;
  updateMap: (e: MapPanelDef) => void;
  index?: number;
  simpleScenes?: SceneSimple[];
  prevScene?: (e: number) => void;
}
export const SceneElement: FC<SceneElementProps> = ({
  scene = new SceneDef(),
  isFullScreen,
  aspectRatio,
  cnvHeight,
  updateMap,
  index = 1,
  simpleScenes,
  prevScene,
}) => {
  const { posterPosition, posterMode, posterContent, mode } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const cnvWidth = (aspectRatio[0] / aspectRatio[1]) * cnvHeight!;
  const [timing, setTiming] = useState<TimeControlDef>(new TimeControlDef(0, 0));
  const canvas = { cnvWidth, cnvHeight };
  const contextValue = useContext(PlayerContext);
  const { time } = contextValue;
  // const [pass, setPass] = useState(0);
  useEffect(() => {
    scene.timeControl ? setTiming(scene.timeControl) : setTiming(new TimeControlDef(0, 0));
  }, [scene.timeControl]);
  /* useEffect(() => {
    if (scene.timeControl.endMS < time && pass < scene.repeat) {
      console.log('vrati');
      prevScene && prevScene(scene.timeControl.startMS);
      setPass((prevState) => ++prevState);
    }
    console.log(time);
  }, [pass, scene.repeat, scene.timeControl.endMS, scene.timeControl.startMS, time]);*/
  const hasLayers = (space: WeatherDataSpaceDef, mapId?: string) => {
    return (
      space.radarMapLayers.length > 0 ||
      space.gribMapLayers.length > 0 ||
      space.satelliteMapLayers.length > 0 ||
      space.observedDataLayers.length > 0 ||
      space.forecastDataLayers.length > 0 ||
      space.symbolLayers.length > 0
    );
  };
  const posterFrame = () => {
    if (posterMode) {
      return [<PosterMarker position={posterPosition} key={'Poster_marker'} />];
    } else return null;
  };
  const images = scene.imagePanels?.map((image) => (
    <ImageElement
      disabled={isFullScreen}
      canvas={canvas}
      key={image.id}
      panelProps={image}
      scene={scene.id}
    />
  ));
  const animations = scene.animationPanels?.map((animation) => (
    <AnimationElement
      sceneId={scene.id}
      disabled={isFullScreen}
      canvas={canvas}
      key={animation.id}
      panelProps={animation}
    />
  ));
  const videos = scene.videoPanels?.map((video) => (
    <VideoElement
      disabled={isFullScreen}
      canvas={canvas}
      key={video.id}
      panelProps={video}
      sceneId={scene.id}
    />
  ));
  const observed = scene.observedWDElements?.map((owd) => {
    const isForPoster = posterContent?.find((item) => item.id === owd.id) as ObservedWDElementDef;
    return (
      <ObservedElement
        sceneId={scene.id}
        disabled={isFullScreen}
        canvas={canvas}
        key={owd.id}
        panelProps={isForPoster || owd}
      />
    );
  });
  const forecast = scene.forecastWDElements?.map((fwd) => {
    const isForPoster = posterContent?.find((item) => item.id === fwd.id) as ForecastWDElementDef;
    return (
      <ForecastElement
        sceneId={scene.id}
        disabled={isFullScreen}
        canvas={canvas}
        key={fwd.id}
        panelProps={isForPoster || fwd}
      />
    );
  });
  const poster = scene.weatherPosters?.map((poster) => (
    <PosterElement
      disabled={isFullScreen}
      canvas={canvas}
      key={poster.id}
      panelProps={poster}
      sceneId={scene.id}
    />
  ));
  const pointDate = scene.pointDates?.map((pointDt) => {
    //@ts-ignore
    const isForPoster = posterContent?.find((item) => item.id === pointDt.id) as PointDateDef;
    return (
      <PointDateElement
        sceneId={scene.id}
        disabled={isFullScreen}
        canvas={canvas}
        key={pointDt.id}
        panelProps={isForPoster || pointDt}
        scene={scene}
      />
    );
  });
  const pointLocation = scene.pointLocation?.map((pointDt) => {
    //@ts-ignore
    const isForPoster = posterContent?.find((item) => item.id === pointDt.id) as PointLocationDef;
    return (
      <PointLocationElement
        sceneId={scene.id}
        disabled={isFullScreen}
        canvas={canvas}
        key={pointDt.id}
        panelProps={isForPoster || pointDt}
        scene={scene}
      />
    );
  });
  const audio = scene.audioElements?.map((audio) => (
    <CanvasAudioElement key={audio.id} panelProps={audio} sceneId={scene.id} />
  ));
  const text = scene.textPanels?.map((text) => {
    const isForPoster = posterContent.find((item) => item.id === text.id) as TextPanelDef;
    return (
      <TextElement
        sceneId={scene.id}
        disabled={isFullScreen}
        canvas={canvas}
        key={text.id}
        panelProps={isForPoster || text}
      />
    );
  });
  const indicators = scene.mapPanels?.map(
    (map) =>
      map?.wdSpace[0].enableTimeframeIndicator &&
      map?.wdSpace[0].mapTimeframeTextIndicator &&
      hasLayers(map?.wdSpace[0], map.id) && (
        <IndicatorElement
          formatString={map?.wdSpace[0].timeframeIndicatorFormat}
          sceneId={scene.id}
          mainIndicator={map?.wdSpace[0].indicator}
          mapId={map.id}
          key={`indicator_${map.id}`}
          disabled={isFullScreen}
          canvas={canvas}
          panelProps={map?.wdSpace[0].mapTimeframeTextIndicator}
          timeFrameIndicator={map?.wdSpace[0].timeframeIndicatorFormat}
          relativeTime={map?.wdSpace[0].relativeTime}
        />
      ),
  );
  const map = scene.mapPanels?.map((map) => {
    if (map.enabled) {
      let sceneFound: SceneSimple | undefined;
      if (mode === ModeEnum.PROJECT) {
        sceneFound = simpleScenes?.find((sc) => sc.id === scene.id);
      }
      return (
        <Fragment key={map.id}>
          <MapElement
            sceneId={scene.id}
            disabled={isFullScreen}
            canvas={canvas}
            panelProps={map}
            onEdit={(e) => updateMap(e)}
            start={sceneFound?.startMS}
            end={sceneFound?.endMS}
            scenesNumber={simpleScenes?.length || 0}
          />
          {map?.wdSpace[0].enableTimeframeIndicator &&
            map?.wdSpace[0].mapTimeframeTextIndicator &&
            hasLayers(map?.wdSpace[0]) && (
              <IndicatorElement
                formatString={map?.wdSpace[0].timeframeIndicatorFormat}
                sceneId={scene.id}
                mainIndicator={map?.wdSpace[0].indicator}
                mapId={map.id}
                disabled={isFullScreen}
                canvas={canvas}
                panelProps={map?.wdSpace[0].mapTimeframeTextIndicator}
                timeFrameIndicator={map?.wdSpace[0].timeframeIndicatorFormat}
                relativeTime={map?.wdSpace[0].relativeTime}
              />
            )}
        </Fragment>
      );
    }
  });
  const legends = getAllLayerSetupsInScene(scene, mode)?.map((l) => {
    console.log(l);
    return (
      <LayerLegendElement
        canvas={canvas}
        disabled={isFullScreen}
        panelProps={l}
        timeControls={l.timeControls}
        key={l.id}
        mapLevel={l.mapLevel}
      />
    );
  });
  const sceneElements = [
    map,
    images,
    animations,
    text,
    observed,
    forecast,
    poster,
    videos,
    audio,
    legends,
    pointDate,
    pointLocation,
    indicators,
    posterFrame(),
    //<Sequence key={1} />,
  ].flat();
  return (
    <div
      id="scene-container"
      className={'scene-container'}
      style={{ zIndex: index + 1 }}
      key={scene.id + sceneElements.length}
    >
      {animate(
        sceneElements,
        getAnimationType(mode === ModeEnum.PROJECT ? timing.inAnimationDef : AnimationsEnum.CUT),
        {
          duration: timing.inAnimationDuration,
          enterTime: timing.startMS,
          currentTime: time,
        },
        getAnimationType(mode === ModeEnum.PROJECT ? timing.outAnimationDef : AnimationsEnum.CUT),
        {
          duration: timing.outAnimationDuration,
          exitTime: timing.endMS,
          currentTime: time,
        },
      )}
    </div>
  );
};
/*

)*/
